import { createSlice } from '@reduxjs/toolkit'

export const matchList = createSlice({
  name: 'matchList',
  initialState: {
    list: [
      {
        "match_id": 9467076,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2024-01-28T05:46:42.000Z",
        "match_end_time": "2024-01-28 08:56:26",
        "created_date": "2024-01-21T16:32:41.000Z",
        "created_by": 1061250,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 458909,
        "ground_name": "JMR CRICBIZ",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "5 wickets",
        "team_a_id": 5365270,
        "team_a": "LAKE RIDGE XI",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1701138975725_mGB3npJMv9wt.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "882617",
        "tournament_name": "JMR ONE DAY T20 CUP",
        "tournament_category_id": "",
        "tournament_round_id": "1671608",
        "tournament_round_name": "Super League",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.837351,
        "longitude": 77.6764868,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 0,
        "match_summary": {
            "team_id": -1,
            "summary": "Fighters Cricket Club won by 5 wickets",
            "short_summary": "",
            "full_summary": "",
            "rrr": "0.00",
            "target": "-"
        },
        "team_a_summary": "127/7",
        "team_a_innings": [
            {
                "team_id": 5365270,
                "inning": 1,
                "inning_start_time": "2024-01-28T05:46:42.000Z",
                "inning_end_time": "2024-01-28T07:26:46.000Z",
                "is_declare": 0,
                "is_forfeited": 0,
                "is_followon": 0,
                "total_run": 127,
                "total_wicket": 7,
                "total_extra": 14,
                "overs_played": "20.0",
                "balls_played": 0,
                "revised_target": 0,
                "revised_overs": 0,
                "lead_by": 0,
                "trail_by": 0,
                "summary": {
                    "score": "127/7",
                    "over": "(20.0 Ov)",
                    "ball": "(0 Ball)",
                    "rr": "6.35"
                }
            }
        ],
        "team_b_summary": "131/5",
        "team_b_innings": [
            {
                "team_id": 4438499,
                "inning": 2,
                "inning_start_time": "2024-01-28T07:39:27.000Z",
                "inning_end_time": "2024-01-28T08:56:20.000Z",
                "is_declare": 0,
                "is_forfeited": 0,
                "is_followon": 0,
                "total_run": 131,
                "total_wicket": 5,
                "total_extra": 15,
                "overs_played": "17.5",
                "balls_played": 0,
                "revised_target": 0,
                "revised_overs": 0,
                "lead_by": 0,
                "trail_by": 0,
                "summary": {
                    "score": "131/5",
                    "over": "(17.5 Ov)",
                    "ball": "(0 Ball)",
                    "rr": "7.35"
                }
            }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to field"
    },
    {
        "match_id": 9467025,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2024-01-28T02:13:00.000Z",
        "match_end_time": "2024-01-28 05:28:54",
        "created_date": "2024-01-21T16:27:43.000Z",
        "created_by": 1061250,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 458909,
        "ground_name": "JMR CRICBIZ",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "1 wicket",
        "team_a_id": 4788334,
        "team_a": "GLF CRICKET CLUB",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1693934893217_8PEkBScEMXDn.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "882617",
        "tournament_name": "JMR ONE DAY T20 CUP",
        "tournament_category_id": "",
        "tournament_round_id": "1671608",
        "tournament_round_name": "Super League",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.837351,
        "longitude": 77.6764868,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 1,
        "match_summary": {
            "team_id": -1,
            "summary": "Fighters Cricket Club won by 1 wicket",
            "short_summary": "",
            "full_summary": "",
            "rrr": "0.00",
            "target": "-"
        },
        "team_a_summary": "101/10",
        "team_a_innings": [
            {
                "team_id": 4788334,
                "inning": 1,
                "inning_start_time": "2024-01-28T02:15:07.000Z",
                "inning_end_time": "2024-01-28T03:42:13.000Z",
                "is_declare": 0,
                "is_forfeited": 0,
                "is_followon": 0,
                "total_run": 101,
                "total_wicket": 10,
                "total_extra": 11,
                "overs_played": "16.5",
                "balls_played": 0,
                "revised_target": 0,
                "revised_overs": 0,
                "lead_by": 0,
                "trail_by": 0,
                "summary": {
                    "score": "101/10",
                    "over": "(16.5 Ov)",
                    "ball": "(0 Ball)",
                    "rr": "6.00"
                }
            }
        ],
        "team_b_summary": "104/9",
        "team_b_innings": [
            {
                "team_id": 4438499,
                "inning": 2,
                "inning_start_time": "2024-01-28T03:56:50.000Z",
                "inning_end_time": "2024-01-28T05:28:52.000Z",
                "is_declare": 0,
                "is_forfeited": 0,
                "is_followon": 0,
                "total_run": 104,
                "total_wicket": 9,
                "total_extra": 13,
                "overs_played": "16.2",
                "balls_played": 0,
                "revised_target": 0,
                "revised_overs": 0,
                "lead_by": 0,
                "trail_by": 0,
                "summary": {
                    "score": "104/9",
                    "over": "(16.2 Ov)",
                    "ball": "(0 Ball)",
                    "rr": "6.37"
                }
            }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to field"
    },
    {
        "match_id": 9525509,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2024-01-26T02:24:00.000Z",
        "match_end_time": "2024-01-26 07:33:26",
        "created_date": "2024-01-26T02:23:05.000Z",
        "created_by": 3024624,
        "city_id": 994,
        "city_name": "Benaulim",
        "ground_id": 624418,
        "ground_name": "Stalwarts",
        "overs": 30,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "4 wickets",
        "team_a_id": 2199334,
        "team_a": "Majestic Royals",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1682423292813_QOQQ4oIIzLr9.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 0,
        "longitude": 0,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 2,
        "match_summary": {
            "team_id": -1,
            "summary": "Fighters Cricket Club won by 4 wickets",
            "short_summary": "",
            "full_summary": "",
            "rrr": "0.00",
            "target": "-"
        },
        "team_a_summary": "198/11",
        "team_a_innings": [
            {
                "team_id": 2199334,
                "inning": 1,
                "inning_start_time": "2024-01-26T02:31:24.000Z",
                "inning_end_time": "2024-01-26T05:04:15.000Z",
                "is_declare": 0,
                "is_forfeited": 0,
                "is_followon": 0,
                "total_run": 198,
                "total_wicket": 11,
                "total_extra": 19,
                "overs_played": "28.2",
                "balls_played": 0,
                "revised_target": 0,
                "revised_overs": 0,
                "lead_by": 0,
                "trail_by": 0,
                "summary": {
                    "score": "198/11",
                    "over": "(28.2 Ov)",
                    "ball": "(0 Ball)",
                    "rr": "6.99"
                }
            }
        ],
        "team_b_summary": "201/7",
        "team_b_innings": [
            {
                "team_id": 4438499,
                "inning": 2,
                "inning_start_time": "2024-01-26T05:22:20.000Z",
                "inning_end_time": "2024-01-26T07:33:22.000Z",
                "is_declare": 0,
                "is_forfeited": 0,
                "is_followon": 0,
                "total_run": 201,
                "total_wicket": 7,
                "total_extra": 42,
                "overs_played": "26.3",
                "balls_played": 0,
                "revised_target": 0,
                "revised_overs": 0,
                "lead_by": 0,
                "trail_by": 0,
                "summary": {
                    "score": "201/7",
                    "over": "(26.3 Ov)",
                    "ball": "(0 Ball)",
                    "rr": "7.58"
                }
            }
        ],
        "toss_details": "Majestic Royals won the toss and elected to bat"
    },
      {
        "match_id": 9295838,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2024-01-20T02:14:16.000Z",
        "match_end_time": "2024-01-20 06:59:26",
        "created_date": "2024-01-13T02:49:22.000Z",
        "created_by": 1061250,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 458909,
        "ground_name": "JMR CRICBIZ",
        "overs": 30,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4314104",
        "winning_team": "Noble Knights CT XII",
        "match_result": "Resulted",
        "win_by": "4 wickets",
        "team_a_id": 4438499,
        "team_a": "Fighters Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4314104,
        "team_b": "Noble Knights CT XII",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1685965115197_2bggG9Xhazme.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.837351,
        "longitude": 77.6764868,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 3,
        "match_summary": {
          "team_id": -1,
          "summary": "Noble Knights CT XII won by 4 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "154/11",
        "team_a_innings": [
          {
            "team_id": 4438499,
            "inning": 1,
            "inning_start_time": "2024-01-20T02:14:16.000Z",
            "inning_end_time": "2024-01-20T04:37:20.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 154,
            "total_wicket": 11,
            "total_extra": 20,
            "overs_played": "28.4",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "154/11",
              "over": "(28.4 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.37"
            }
          }
        ],
        "team_b_summary": "155/6",
        "team_b_innings": [
          {
            "team_id": 4314104,
            "inning": 2,
            "inning_start_time": "2024-01-20T04:58:36.000Z",
            "inning_end_time": "2024-01-20T06:59:20.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 155,
            "total_wicket": 6,
            "total_extra": 22,
            "overs_played": "27.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "155/6",
              "over": "(27.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.74"
            }
          }
        ],
        "toss_details": "Noble Knights CT XII won the toss and elected to field"
      },
      {
        "match_id": 9220490,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2024-01-14T02:51:51.000Z",
        "match_end_time": "2024-01-14 08:21:26",
        "created_date": "2024-01-07T18:06:02.000Z",
        "created_by": 375421,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 7991,
        "ground_name": "SBS Cricket Ground",
        "overs": 30,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "1 wicket",
        "team_a_id": 160992,
        "team_a": "Wicketers",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1624131195552_ESLh36WwaSFn.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "504497",
        "tournament_name": "SBS PRACTICE MATCHES",
        "tournament_category_id": "",
        "tournament_round_id": "900647",
        "tournament_round_name": "Warm up Match",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.9715987,
        "longitude": 77.59456269999998,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 4,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 1 wicket",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "245/8",
        "team_a_innings": [
          {
            "team_id": 160992,
            "inning": 1,
            "inning_start_time": "2024-01-14T02:51:51.000Z",
            "inning_end_time": "2024-01-14T05:18:03.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 245,
            "total_wicket": 8,
            "total_extra": 23,
            "overs_played": "30.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "245/8",
              "over": "(30.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "8.17"
            }
          }
        ],
        "team_b_summary": "250/9",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2024-01-14T05:45:13.000Z",
            "inning_end_time": "2024-01-14T08:21:24.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 250,
            "total_wicket": 9,
            "total_extra": 26,
            "overs_played": "30.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "250/9",
              "over": "(30.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "8.33"
            }
          }
        ],
        "toss_details": "Wicketers won the toss and elected to bat"
      },
      {
        "match_id": 9257490,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2024-01-13T02:32:54.000Z",
        "match_end_time": "2024-01-13 05:52:22",
        "created_date": "2024-01-10T14:24:15.000Z",
        "created_by": 3024624,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 139056,
        "ground_name": "Bouncers cricket Ground .",
        "overs": 30,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "10 wickets",
        "team_a_id": 5453243,
        "team_a": "Bangalore Marco Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1701095555829_Rx2e5ZkxeaWc.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "852833",
        "tournament_name": "DSE LEAGUE 2024",
        "tournament_category_id": "",
        "tournament_round_id": "1611855",
        "tournament_round_name": "League Matches",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8011611,
        "longitude": 77.7142672,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 5,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 10 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "132/10",
        "team_a_innings": [
          {
            "team_id": 5453243,
            "inning": 1,
            "inning_start_time": "2024-01-13T02:32:54.000Z",
            "inning_end_time": "2024-01-13T04:29:22.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 132,
            "total_wicket": 10,
            "total_extra": 22,
            "overs_played": "24.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "132/10",
              "over": "(24.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.50"
            }
          }
        ],
        "team_b_summary": "136/0",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2024-01-13T04:47:10.000Z",
            "inning_end_time": "2024-01-13T05:52:13.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 136,
            "total_wicket": 0,
            "total_extra": 16,
            "overs_played": "14.2",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "136/0",
              "over": "(14.2 Ov)",
              "ball": "(0 Ball)",
              "rr": "9.49"
            }
          }
        ],
        "toss_details": "Bangalore Marco Cricket Club won the toss and elected to bat"
      },
      {
        "match_id": 9192119,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2024-01-07T02:23:21.000Z",
        "match_end_time": "2024-01-07 06:28:44",
        "created_date": "2024-01-07T02:09:31.000Z",
        "created_by": 12081897,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 458909,
        "ground_name": "JMR CRICBIZ",
        "overs": 30,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "869120",
        "winning_team": "Challengers XI BLR",
        "match_result": "Resulted",
        "win_by": "196 runs",
        "team_a_id": 869120,
        "team_a": "Challengers XI BLR",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1637135516596_FKEEUr5TJw3k.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.837351,
        "longitude": 77.6764868,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 6,
        "match_summary": {
          "team_id": -1,
          "summary": "Challengers XI BLR won by 196 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "253/8",
        "team_a_innings": [
          {
            "team_id": 869120,
            "inning": 1,
            "inning_start_time": "2024-01-07T02:23:21.000Z",
            "inning_end_time": "2024-01-07T04:41:54.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 253,
            "total_wicket": 8,
            "total_extra": 45,
            "overs_played": "30.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "253/8",
              "over": "(30.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "8.43"
            }
          }
        ],
        "team_b_summary": "57/10",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2024-01-07T05:02:01.000Z",
            "inning_end_time": "2024-01-07T06:28:36.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 57,
            "total_wicket": 10,
            "total_extra": 5,
            "overs_played": "19.1",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "57/10",
              "over": "(19.1 Ov)",
              "ball": "(0 Ball)",
              "rr": "2.97"
            }
          }
        ],
        "toss_details": "Challengers XI BLR won the toss and elected to bat"
      },
      {
        "match_id": 9180300,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2024-01-06T09:17:00.000Z",
        "match_end_time": "2024-01-06 12:40:31",
        "created_date": "2024-01-06T09:16:06.000Z",
        "created_by": 21826046,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 597837,
        "ground_name": "Ram Sports Ground",
        "overs": 20,
        "balls": null,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "3 wickets",
        "team_a_id": 5404514,
        "team_a": "Vintage Cox Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1700888123367_r0LhoAO1XRUZ.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "856186",
        "tournament_name": "Cricket Clash Championship Edition -4",
        "tournament_category_id": "",
        "tournament_round_id": "1617358",
        "tournament_round_name": "Super Three",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8728896,
        "longitude": 77.4723383,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 7,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 3 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "124/10",
        "team_a_innings": [
          {
            "team_id": 5404514,
            "inning": 1,
            "inning_start_time": "2024-01-06T09:17:42.000Z",
            "inning_end_time": "2024-01-06T10:53:21.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 124,
            "total_wicket": 10,
            "total_extra": 18,
            "overs_played": "19.4",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "124/10",
              "over": "(19.4 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.31"
            }
          }
        ],
        "team_b_summary": "125/7",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2024-01-06T11:05:52.000Z",
            "inning_end_time": "2024-01-06T12:40:28.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 125,
            "total_wicket": 7,
            "total_extra": 25,
            "overs_played": "18.4",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "125/7",
              "over": "(18.4 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.70"
            }
          }
        ],
        "toss_details": "Vintage Cox Club won the toss and elected to bat"
      },
      {
        "match_id": 9169085,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2024-01-06T02:28:53.000Z",
        "match_end_time": "2024-01-06 05:29:45",
        "created_date": "2024-01-06T01:44:13.000Z",
        "created_by": 289659,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 597837,
        "ground_name": "Ram Sports Ground",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "5 wickets",
        "team_a_id": 1097024,
        "team_a": "Karnataka 11",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1693307816351_7jgCQFGHhtlY.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "856186",
        "tournament_name": "Cricket Clash Championship Edition -4",
        "tournament_category_id": "",
        "tournament_round_id": "1617358",
        "tournament_round_name": "Super Three",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8728896,
        "longitude": 77.4723383,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 8,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 5 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "109/9",
        "team_a_innings": [
          {
            "team_id": 1097024,
            "inning": 1,
            "inning_start_time": "2024-01-06T02:28:53.000Z",
            "inning_end_time": "2024-01-06T04:22:14.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 109,
            "total_wicket": 9,
            "total_extra": 10,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "109/9",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.45"
            }
          }
        ],
        "team_b_summary": "110/5",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2024-01-06T04:23:50.000Z",
            "inning_end_time": "2024-01-06T05:29:42.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 110,
            "total_wicket": 5,
            "total_extra": 18,
            "overs_played": "13.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "110/5",
              "over": "(13.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "8.46"
            }
          }
        ],
        "toss_details": "Karnataka 11 won the toss and elected to bat"
      },
      {
        "match_id": 9042608,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2023-12-31T02:00:00.000Z",
        "match_end_time": "2023-12-31 06:18:10",
        "created_date": "2023-12-29T05:42:12.000Z",
        "created_by": 1061250,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 458909,
        "ground_name": "JMR CRICBIZ",
        "overs": 30,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "3 wickets",
        "team_a_id": 869120,
        "team_a": "Challengers XI BLR",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1637135516596_FKEEUr5TJw3k.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.837351,
        "longitude": 77.6764868,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 9,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 3 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "144/10",
        "team_a_innings": [
          {
            "team_id": 869120,
            "inning": 1,
            "inning_start_time": "2023-12-31T02:17:50.000Z",
            "inning_end_time": "2023-12-31T04:04:02.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 144,
            "total_wicket": 10,
            "total_extra": 29,
            "overs_played": "18.5",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "144/10",
              "over": "(18.5 Ov)",
              "ball": "(0 Ball)",
              "rr": "7.65"
            }
          }
        ],
        "team_b_summary": "146/7",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-12-31T04:23:53.000Z",
            "inning_end_time": "2023-12-31T06:18:03.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 146,
            "total_wicket": 7,
            "total_extra": 11,
            "overs_played": "28.1",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "146/7",
              "over": "(28.1 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.18"
            }
          }
        ],
        "toss_details": "Challengers XI BLR won the toss and elected to bat"
      },
      {
        "match_id": 9002495,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2023-12-30T02:17:00.000Z",
        "match_end_time": "2023-12-30 07:05:52",
        "created_date": "2023-12-26T05:59:53.000Z",
        "created_by": 1061250,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 458909,
        "ground_name": "JMR CRICBIZ",
        "overs": 30,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "152 runs",
        "team_a_id": 4438499,
        "team_a": "Fighters Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4213227,
        "team_b": "NB Sunrisers",
        "team_b_logo": "https://media.cricheroes.in/default/teamintital/NS.png",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.837351,
        "longitude": 77.6764868,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 0,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 152 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "236/9",
        "team_a_innings": [
          {
            "team_id": 4438499,
            "inning": 1,
            "inning_start_time": "2023-12-30T02:36:16.000Z",
            "inning_end_time": "2023-12-30T05:05:37.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 236,
            "total_wicket": 9,
            "total_extra": 17,
            "overs_played": "30.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "236/9",
              "over": "(30.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "7.87"
            }
          }
        ],
        "team_b_summary": "84/10",
        "team_b_innings": [
          {
            "team_id": 4213227,
            "inning": 2,
            "inning_start_time": "2023-12-30T05:30:04.000Z",
            "inning_end_time": "2023-12-30T07:05:47.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 84,
            "total_wicket": 10,
            "total_extra": 21,
            "overs_played": "19.3",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "84/10",
              "over": "(19.3 Ov)",
              "ball": "(0 Ball)",
              "rr": "4.31"
            }
          }
        ],
        "toss_details": "NB Sunrisers won the toss and elected to field"
      },
      {
        "match_id": 8794115,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2023-12-17T02:34:17.000Z",
        "match_end_time": "2023-12-17 06:44:52",
        "created_date": "2023-12-12T18:08:36.000Z",
        "created_by": 3024624,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 139056,
        "ground_name": "Bouncers cricket Ground .",
        "overs": 30,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "241219",
        "winning_team": "Axi-Club",
        "match_result": "Resulted",
        "win_by": "206 runs",
        "team_a_id": 241219,
        "team_a": "Axi-Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1643979446246_2yJc1SDE9ikn.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "549914",
        "tournament_name": "DSE PRACTICE MATCHES 2023",
        "tournament_category_id": "",
        "tournament_round_id": "993861",
        "tournament_round_name": "Super League",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8011611,
        "longitude": 77.7142672,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 1,
        "match_summary": {
          "team_id": -1,
          "summary": "Axi-Club won by 206 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "291/5",
        "team_a_innings": [
          {
            "team_id": 241219,
            "inning": 1,
            "inning_start_time": "2023-12-17T02:34:17.000Z",
            "inning_end_time": "2023-12-17T05:02:31.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 291,
            "total_wicket": 5,
            "total_extra": 10,
            "overs_played": "30.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "291/5",
              "over": "(30.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "9.70"
            }
          }
        ],
        "team_b_summary": "85/11",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-12-17T05:25:34.000Z",
            "inning_end_time": "2023-12-17T06:44:37.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 85,
            "total_wicket": 11,
            "total_extra": 11,
            "overs_played": "15.4",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "85/11",
              "over": "(15.4 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.43"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to field"
      },
      {
        "match_id": 8792219,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2023-12-16T02:23:19.000Z",
        "match_end_time": "2023-12-16 07:04:25",
        "created_date": "2023-12-12T14:07:55.000Z",
        "created_by": 3024624,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 431030,
        "ground_name": "Aastha Turf Ground (DSE Group)",
        "overs": 30,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "5453243",
        "winning_team": "Bangalore Marco Cricket Club",
        "match_result": "Resulted",
        "win_by": "3 runs",
        "team_a_id": 5453243,
        "team_a": "Bangalore Marco Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1701095555829_Rx2e5ZkxeaWc.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "549914",
        "tournament_name": "DSE PRACTICE MATCHES 2023",
        "tournament_category_id": "",
        "tournament_round_id": "993861",
        "tournament_round_name": "Super League",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8775492,
        "longitude": 77.7217951,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 2,
        "match_summary": {
          "team_id": -1,
          "summary": "Bangalore Marco Cricket Club won by 3 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "157/9",
        "team_a_innings": [
          {
            "team_id": 5453243,
            "inning": 1,
            "inning_start_time": "2023-12-16T02:23:19.000Z",
            "inning_end_time": "2023-12-16T04:48:01.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 157,
            "total_wicket": 9,
            "total_extra": 27,
            "overs_played": "30.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "157/9",
              "over": "(30.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.23"
            }
          }
        ],
        "team_b_summary": "154/10",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-12-16T05:05:59.000Z",
            "inning_end_time": "2023-12-16T07:04:17.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 154,
            "total_wicket": 10,
            "total_extra": 17,
            "overs_played": "24.2",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "154/10",
              "over": "(24.2 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.33"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to field"
      },
      {
        "match_id": 8711736,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2023-12-10T02:11:00.000Z",
        "match_end_time": "2023-12-10 06:49:33",
        "created_date": "2023-12-06T13:01:45.000Z",
        "created_by": 3024624,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 13488,
        "ground_name": "TDR Sports Ground",
        "overs": 30,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "2 wickets",
        "team_a_id": 4262584,
        "team_a": "Comrades CC",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1680853242483_mWDMpmcycrCO.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "549914",
        "tournament_name": "DSE PRACTICE MATCHES 2023",
        "tournament_category_id": "",
        "tournament_round_id": "993861",
        "tournament_round_name": "Super League",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8929664,
        "longitude": 77.739219,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 3,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 2 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "126/10",
        "team_a_innings": [
          {
            "team_id": 4262584,
            "inning": 1,
            "inning_start_time": "2023-12-10T02:16:43.000Z",
            "inning_end_time": "2023-12-10T04:27:53.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 126,
            "total_wicket": 10,
            "total_extra": 10,
            "overs_played": "27.2",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "126/10",
              "over": "(27.2 Ov)",
              "ball": "(0 Ball)",
              "rr": "4.61"
            }
          }
        ],
        "team_b_summary": "129/8",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-12-10T04:47:36.000Z",
            "inning_end_time": "2023-12-10T06:49:25.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 129,
            "total_wicket": 8,
            "total_extra": 16,
            "overs_played": "25.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "129/8",
              "over": "(25.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.16"
            }
          }
        ],
        "toss_details": "Comrades CC won the toss and elected to bat"
      },
      {
        "match_id": 8705540,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2023-12-09T02:44:35.000Z",
        "match_end_time": "2023-12-09 06:58:09",
        "created_date": "2023-12-05T16:26:33.000Z",
        "created_by": 3024624,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 431030,
        "ground_name": "Aastha Turf Ground (DSE Group)",
        "overs": 30,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "1338247",
        "winning_team": "CrickOrioles Club",
        "match_result": "Resulted",
        "win_by": "12 runs",
        "team_a_id": 1338247,
        "team_a": "CrickOrioles Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1618635342962_SBueNArVfSUj.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "549914",
        "tournament_name": "DSE PRACTICE MATCHES 2023",
        "tournament_category_id": "",
        "tournament_round_id": "993861",
        "tournament_round_name": "Super League",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8775492,
        "longitude": 77.7217951,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 4,
        "match_summary": {
          "team_id": -1,
          "summary": "CrickOrioles Club won by 12 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "150/9",
        "team_a_innings": [
          {
            "team_id": 1338247,
            "inning": 1,
            "inning_start_time": "2023-12-09T02:44:35.000Z",
            "inning_end_time": "2023-12-09T04:55:57.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 150,
            "total_wicket": 9,
            "total_extra": 20,
            "overs_played": "30.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "150/9",
              "over": "(30.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.00"
            }
          }
        ],
        "team_b_summary": "138/11",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-12-09T05:10:27.000Z",
            "inning_end_time": "2023-12-09T06:58:01.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 138,
            "total_wicket": 11,
            "total_extra": 21,
            "overs_played": "23.5",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "138/11",
              "over": "(23.5 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.79"
            }
          }
        ],
        "toss_details": "CrickOrioles Club won the toss and elected to bat"
      },
      {
        "match_id": 8622570,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2023-12-03T02:18:55.000Z",
        "match_end_time": "2023-12-03 06:53:24",
        "created_date": "2023-11-28T15:14:17.000Z",
        "created_by": 14199274,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 431030,
        "ground_name": "Aastha Turf Ground (DSE Group)",
        "overs": 30,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "585016",
        "winning_team": "Bangalore Mavericks",
        "match_result": "Resulted",
        "win_by": "68 runs",
        "team_a_id": 585016,
        "team_a": "Bangalore Mavericks",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1579594581102_TS8WifuWOzOQ.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Clubs",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "549914",
        "tournament_name": "DSE PRACTICE MATCHES 2023",
        "tournament_category_id": "",
        "tournament_round_id": "993861",
        "tournament_round_name": "Super League",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8775492,
        "longitude": 77.7217951,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 5,
        "match_summary": {
          "team_id": -1,
          "summary": "Bangalore Mavericks won by 68 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "232/8",
        "team_a_innings": [
          {
            "team_id": 585016,
            "inning": 1,
            "inning_start_time": "2023-12-03T02:18:55.000Z",
            "inning_end_time": "2023-12-03T04:47:29.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 232,
            "total_wicket": 8,
            "total_extra": 48,
            "overs_played": "30.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "232/8",
              "over": "(30.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "7.73"
            }
          }
        ],
        "team_b_summary": "164/10",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-12-03T05:02:28.000Z",
            "inning_end_time": "2023-12-03T06:53:13.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 164,
            "total_wicket": 10,
            "total_extra": 41,
            "overs_played": "25.1",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "164/10",
              "over": "(25.1 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.52"
            }
          }
        ],
        "toss_details": "Fighters Cricket Clubs won the toss and elected to field"
      },
      {
        "match_id": 8632496,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2023-12-02T02:22:30.000Z",
        "match_end_time": "2023-12-02 07:17:03",
        "created_date": "2023-11-29T18:22:10.000Z",
        "created_by": 3024624,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 431030,
        "ground_name": "Aastha Turf Ground (DSE Group)",
        "overs": 30,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "2 wickets",
        "team_a_id": 4972773,
        "team_a": "The Underdogs",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1692764150705_GioBpuQIRHsI.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "549914",
        "tournament_name": "DSE PRACTICE MATCHES 2023",
        "tournament_category_id": "",
        "tournament_round_id": "993861",
        "tournament_round_name": "Super League",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8775492,
        "longitude": 77.7217951,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 6,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 2 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "164/10",
        "team_a_innings": [
          {
            "team_id": 4972773,
            "inning": 1,
            "inning_start_time": "2023-12-02T02:22:30.000Z",
            "inning_end_time": "2023-12-02T05:02:51.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 164,
            "total_wicket": 10,
            "total_extra": 40,
            "overs_played": "30.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "164/10",
              "over": "(30.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.47"
            }
          }
        ],
        "team_b_summary": "165/8",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-12-02T05:17:46.000Z",
            "inning_end_time": "2023-12-02T07:16:55.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 165,
            "total_wicket": 8,
            "total_extra": 20,
            "overs_played": "25.1",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "165/8",
              "over": "(25.1 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.56"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to field"
      },
      {
        "match_id": 8578282,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2023-11-26T02:24:00.000Z",
        "match_end_time": "2023-11-26 06:19:40",
        "created_date": "2023-11-25T08:01:30.000Z",
        "created_by": 3024624,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 13488,
        "ground_name": "TDR Sports Ground",
        "overs": 30,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "989545",
        "winning_team": "Rakshanakramana Cricket Club (RCC)",
        "match_result": "Resulted",
        "win_by": "8 wickets",
        "team_a_id": 4438499,
        "team_a": "Fighters Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 989545,
        "team_b": "Rakshanakramana Cricket Club (RCC)",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1693735379617_b9hza0jvucJo.jpg",
        "is_b_home_team": 0,
        "tournament_id": "735586",
        "tournament_name": "Eminents Challenger Cup 8.0",
        "tournament_category_id": "",
        "tournament_round_id": "1376907",
        "tournament_round_name": "League Matches",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8929664,
        "longitude": 77.739219,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 7,
        "match_summary": {
          "team_id": -1,
          "summary": "Rakshanakramana Cricket Club (RCC) won by 8 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "134/10",
        "team_a_innings": [
          {
            "team_id": 4438499,
            "inning": 1,
            "inning_start_time": "2023-11-26T02:29:04.000Z",
            "inning_end_time": "2023-11-26T04:38:33.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 134,
            "total_wicket": 10,
            "total_extra": 13,
            "overs_played": "28.1",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "134/10",
              "over": "(28.1 Ov)",
              "ball": "(0 Ball)",
              "rr": "4.76"
            }
          }
        ],
        "team_b_summary": "137/2",
        "team_b_innings": [
          {
            "team_id": 989545,
            "inning": 2,
            "inning_start_time": "2023-11-26T04:53:45.000Z",
            "inning_end_time": "2023-11-26T06:19:37.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 137,
            "total_wicket": 2,
            "total_extra": 19,
            "overs_played": "21.3",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "137/2",
              "over": "(21.3 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.37"
            }
          }
        ],
        "toss_details": "Rakshanakramana Cricket Club (RCC) won the toss and elected to field"
      },
      {
        "match_id": 8555810,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2023-11-25T02:46:00.000Z",
        "match_end_time": "2023-11-25 07:11:23",
        "created_date": "2023-11-23T08:44:18.000Z",
        "created_by": 3024624,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 139056,
        "ground_name": "Bouncers cricket Ground .",
        "overs": 30,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "3 wickets",
        "team_a_id": 241219,
        "team_a": "Axi-Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1643979446246_2yJc1SDE9ikn.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "549914",
        "tournament_name": "DSE PRACTICE MATCHES 2023",
        "tournament_category_id": "",
        "tournament_round_id": "993861",
        "tournament_round_name": "Super League",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8011611,
        "longitude": 77.7142672,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 8,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 3 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "132/10",
        "team_a_innings": [
          {
            "team_id": 241219,
            "inning": 1,
            "inning_start_time": "2023-11-25T02:50:27.000Z",
            "inning_end_time": "2023-11-25T05:10:49.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 132,
            "total_wicket": 10,
            "total_extra": 29,
            "overs_played": "30.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "132/10",
              "over": "(30.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "4.40"
            }
          }
        ],
        "team_b_summary": "135/7",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-11-25T05:29:34.000Z",
            "inning_end_time": "2023-11-25T07:11:20.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 135,
            "total_wicket": 7,
            "total_extra": 12,
            "overs_played": "25.3",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "135/7",
              "over": "(25.3 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.29"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to field"
      },
      {
        "match_id": 8560925,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-11-24T01:09:04.000Z",
        "match_end_time": "2023-11-24 02:41:42",
        "created_date": "2023-11-24T00:08:05.000Z",
        "created_by": 16191660,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 42487,
        "ground_name": "Iqra Games Village (IGV)",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "5060042",
        "winning_team": "Bengaluru Titans",
        "match_result": "Resulted",
        "win_by": "5 wickets",
        "team_a_id": 4438499,
        "team_a": "Fighters Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 5060042,
        "team_b": "Bengaluru Titans",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1700498455702_QFsEt9DDNj33.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8868089,
        "longitude": 77.6431831,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 9,
        "match_summary": {
          "team_id": -1,
          "summary": "Bengaluru Titans won by 5 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "66/10",
        "team_a_innings": [
          {
            "team_id": 4438499,
            "inning": 1,
            "inning_start_time": "2023-11-24T01:09:04.000Z",
            "inning_end_time": "2023-11-24T02:04:46.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 66,
            "total_wicket": 10,
            "total_extra": 6,
            "overs_played": "15.4",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "66/10",
              "over": "(15.4 Ov)",
              "ball": "(0 Ball)",
              "rr": "4.21"
            }
          }
        ],
        "team_b_summary": "67/5",
        "team_b_innings": [
          {
            "team_id": 5060042,
            "inning": 2,
            "inning_start_time": "2023-11-24T02:08:23.000Z",
            "inning_end_time": "2023-11-24T02:41:42.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 67,
            "total_wicket": 5,
            "total_extra": 8,
            "overs_played": "10.2",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "67/5",
              "over": "(10.2 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.48"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to bat"
      },
      {
        "match_id": 8506846,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2023-11-19T02:17:00.000Z",
        "match_end_time": "2023-11-19 06:05:50",
        "created_date": "2023-11-18T09:09:07.000Z",
        "created_by": 3024624,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 431030,
        "ground_name": "Aastha Turf Ground (DSE Group)",
        "overs": 30,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4937499",
        "winning_team": "LETHAL STRIKERS CRICKET CLUB(LSCC)",
        "match_result": "Resulted",
        "win_by": "2 wickets",
        "team_a_id": 4438499,
        "team_a": "Fighters Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4937499,
        "team_b": "LETHAL STRIKERS CRICKET CLUB(LSCC)",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1695206846370_JtbA8N6Sxiux.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "549914",
        "tournament_name": "DSE PRACTICE MATCHES 2023",
        "tournament_category_id": "",
        "tournament_round_id": "993861",
        "tournament_round_name": "Super League",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8775492,
        "longitude": 77.7217951,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 0,
        "match_summary": {
          "team_id": -1,
          "summary": "LETHAL STRIKERS CRICKET CLUB(LSCC) won by 2 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "124/11",
        "team_a_innings": [
          {
            "team_id": 4438499,
            "inning": 1,
            "inning_start_time": "2023-11-19T02:24:48.000Z",
            "inning_end_time": "2023-11-19T04:06:11.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 124,
            "total_wicket": 11,
            "total_extra": 23,
            "overs_played": "21.3",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "124/11",
              "over": "(21.3 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.77"
            }
          }
        ],
        "team_b_summary": "125/9",
        "team_b_innings": [
          {
            "team_id": 4937499,
            "inning": 2,
            "inning_start_time": "2023-11-19T04:22:49.000Z",
            "inning_end_time": "2023-11-19T06:05:36.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 125,
            "total_wicket": 9,
            "total_extra": 22,
            "overs_played": "25.1",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "125/9",
              "over": "(25.1 Ov)",
              "ball": "(0 Ball)",
              "rr": "4.97"
            }
          }
        ],
        "toss_details": "LETHAL STRIKERS CRICKET CLUB(LSCC) won the toss and elected to field"
      },
      {
        "match_id": 8496589,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2023-11-18T03:04:30.000Z",
        "match_end_time": "2023-11-18 07:41:06",
        "created_date": "2023-11-17T13:37:26.000Z",
        "created_by": 3024624,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 139056,
        "ground_name": "Bouncers cricket Ground .",
        "overs": 30,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "70 runs",
        "team_a_id": 4438499,
        "team_a": "Fighters Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 5365985,
        "team_b": "Cognizant Bangalore",
        "team_b_logo": "https://media.cricheroes.in/default/teamintital/CB.png",
        "is_b_home_team": 0,
        "tournament_id": "549914",
        "tournament_name": "DSE PRACTICE MATCHES 2023",
        "tournament_category_id": "",
        "tournament_round_id": "993861",
        "tournament_round_name": "Super League",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8011611,
        "longitude": 77.7142672,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 1,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 70 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "209/9",
        "team_a_innings": [
          {
            "team_id": 4438499,
            "inning": 1,
            "inning_start_time": "2023-11-18T03:04:30.000Z",
            "inning_end_time": "2023-11-18T05:43:31.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 209,
            "total_wicket": 9,
            "total_extra": 48,
            "overs_played": "30.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "209/9",
              "over": "(30.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.97"
            }
          }
        ],
        "team_b_summary": "139/11",
        "team_b_innings": [
          {
            "team_id": 5365985,
            "inning": 2,
            "inning_start_time": "2023-11-18T05:44:06.000Z",
            "inning_end_time": "2023-11-18T07:40:58.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 139,
            "total_wicket": 11,
            "total_extra": 27,
            "overs_played": "21.4",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "139/11",
              "over": "(21.4 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.42"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to bat"
      },
      {
        "match_id": 8433466,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2023-11-12T02:25:18.000Z",
        "match_end_time": "2023-11-12 06:06:04",
        "created_date": "2023-11-11T13:56:38.000Z",
        "created_by": 3024624,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 139056,
        "ground_name": "Bouncers cricket Ground .",
        "overs": 30,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "5 wickets",
        "team_a_id": 2096200,
        "team_a": "THE ROYAL GHAZIS",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1704305431531_WC1lSc0dtgLf.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "549914",
        "tournament_name": "DSE PRACTICE MATCHES 2023",
        "tournament_category_id": "",
        "tournament_round_id": "993861",
        "tournament_round_name": "Super League",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8011611,
        "longitude": 77.7142672,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 2,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 5 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "98/10",
        "team_a_innings": [
          {
            "team_id": 2096200,
            "inning": 1,
            "inning_start_time": "2023-11-12T02:25:18.000Z",
            "inning_end_time": "2023-11-12T04:18:42.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 98,
            "total_wicket": 10,
            "total_extra": 11,
            "overs_played": "23.5",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "98/10",
              "over": "(23.5 Ov)",
              "ball": "(0 Ball)",
              "rr": "4.11"
            }
          }
        ],
        "team_b_summary": "99/5",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-11-12T04:37:04.000Z",
            "inning_end_time": "2023-11-12T06:05:57.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 99,
            "total_wicket": 5,
            "total_extra": 13,
            "overs_played": "24.1",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "99/5",
              "over": "(24.1 Ov)",
              "ball": "(0 Ball)",
              "rr": "4.10"
            }
          }
        ],
        "toss_details": "THE ROYAL GHAZIS won the toss and elected to bat"
      },
      {
        "match_id": 8391990,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2023-11-11T02:31:16.000Z",
        "match_end_time": "2023-11-11 07:04:15",
        "created_date": "2023-11-07T05:45:58.000Z",
        "created_by": 3024624,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 139056,
        "ground_name": "Bouncers cricket Ground .",
        "overs": 30,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "5079993",
        "winning_team": "Rebels X1 Cricket Club",
        "match_result": "Resulted",
        "win_by": "53 runs",
        "team_a_id": 5079993,
        "team_a": "Rebels X1 Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1695181696645_hDoO9g82XtJP.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "549914",
        "tournament_name": "DSE PRACTICE MATCHES 2023",
        "tournament_category_id": "",
        "tournament_round_id": "993861",
        "tournament_round_name": "Super League",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8011611,
        "longitude": 77.7142672,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 3,
        "match_summary": {
          "team_id": -1,
          "summary": "Rebels X1 Cricket Club won by 53 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "154/9",
        "team_a_innings": [
          {
            "team_id": 5079993,
            "inning": 1,
            "inning_start_time": "2023-11-11T02:31:16.000Z",
            "inning_end_time": "2023-11-11T04:49:26.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 154,
            "total_wicket": 9,
            "total_extra": 13,
            "overs_played": "30.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "154/9",
              "over": "(30.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.13"
            }
          }
        ],
        "team_b_summary": "101/10",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-11-11T05:06:52.000Z",
            "inning_end_time": "2023-11-11T07:03:58.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 101,
            "total_wicket": 10,
            "total_extra": 20,
            "overs_played": "26.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "101/10",
              "over": "(26.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "3.88"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to field"
      },
      {
        "match_id": 8383760,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-11-10T01:12:42.000Z",
        "match_end_time": "2023-11-10 03:40:03",
        "created_date": "2023-11-06T05:05:44.000Z",
        "created_by": 16191660,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 42487,
        "ground_name": "Iqra Games Village (IGV)",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "33 runs",
        "team_a_id": 4438499,
        "team_a": "Fighters Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 5060042,
        "team_b": "Bengaluru Titans",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1700498455702_QFsEt9DDNj33.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8868089,
        "longitude": 77.6431831,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 4,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 33 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "146/6",
        "team_a_innings": [
          {
            "team_id": 4438499,
            "inning": 1,
            "inning_start_time": "2023-11-10T01:12:42.000Z",
            "inning_end_time": "2023-11-10T02:29:29.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 146,
            "total_wicket": 6,
            "total_extra": 16,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "146/6",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "7.30"
            }
          }
        ],
        "team_b_summary": "113/10",
        "team_b_innings": [
          {
            "team_id": 5060042,
            "inning": 2,
            "inning_start_time": "2023-11-10T02:35:32.000Z",
            "inning_end_time": "2023-11-10T03:40:02.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 113,
            "total_wicket": 10,
            "total_extra": 15,
            "overs_played": "16.5",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "113/10",
              "over": "(16.5 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.71"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to bat"
      },
      {
        "match_id": 8291735,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2023-10-29T02:19:00.000Z",
        "match_end_time": "2023-10-29 07:28:29",
        "created_date": "2023-10-29T02:09:34.000Z",
        "created_by": 13570465,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 579164,
        "ground_name": "Gladiators Den Cricket Ground",
        "overs": 30,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "5181701",
        "winning_team": "Powerblaze Cricket Club",
        "match_result": "Resulted",
        "win_by": "47 runs",
        "team_a_id": 5181701,
        "team_a": "Powerblaze Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/default/teamintital/PC.png",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8701875,
        "longitude": 77.7586875,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 5,
        "match_summary": {
          "team_id": -1,
          "summary": "Powerblaze Cricket Club won by 47 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "227/7",
        "team_a_innings": [
          {
            "team_id": 5181701,
            "inning": 1,
            "inning_start_time": "2023-10-29T02:31:13.000Z",
            "inning_end_time": "2023-10-29T04:50:18.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 227,
            "total_wicket": 7,
            "total_extra": 26,
            "overs_played": "30.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "227/7",
              "over": "(30.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "7.57"
            }
          }
        ],
        "team_b_summary": "180/10",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-10-29T05:10:02.000Z",
            "inning_end_time": "2023-10-29T07:28:26.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 180,
            "total_wicket": 10,
            "total_extra": 30,
            "overs_played": "28.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "180/10",
              "over": "(28.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.43"
            }
          }
        ],
        "toss_details": "Powerblaze Cricket Club won the toss and elected to bat"
      },
      {
        "match_id": 8253186,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2023-10-28T07:31:00.000Z",
        "match_end_time": "2023-10-28 11:51:46",
        "created_date": "2023-10-25T08:58:46.000Z",
        "created_by": 3024624,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 139056,
        "ground_name": "Bouncers cricket Ground .",
        "overs": 30,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "4 wickets",
        "team_a_id": 241219,
        "team_a": "Axi-Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1643979446246_2yJc1SDE9ikn.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "549914",
        "tournament_name": "DSE PRACTICE MATCHES 2023",
        "tournament_category_id": "",
        "tournament_round_id": "993861",
        "tournament_round_name": "Super League",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8011611,
        "longitude": 77.7142672,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 6,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 4 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "114/10",
        "team_a_innings": [
          {
            "team_id": 241219,
            "inning": 1,
            "inning_start_time": "2023-10-28T07:35:35.000Z",
            "inning_end_time": "2023-10-28T09:37:05.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 114,
            "total_wicket": 10,
            "total_extra": 24,
            "overs_played": "19.4",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "114/10",
              "over": "(19.4 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.80"
            }
          }
        ],
        "team_b_summary": "115/6",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-10-28T09:58:44.000Z",
            "inning_end_time": "2023-10-28T11:51:38.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 115,
            "total_wicket": 6,
            "total_extra": 7,
            "overs_played": "23.3",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "115/6",
              "over": "(23.3 Ov)",
              "ball": "(0 Ball)",
              "rr": "4.89"
            }
          }
        ],
        "toss_details": "Axi-Club won the toss and elected to bat"
      },
      {
        "match_id": 8251909,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-10-27T01:09:54.000Z",
        "match_end_time": "2023-10-27 03:44:14",
        "created_date": "2023-10-25T06:16:49.000Z",
        "created_by": 4412996,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 42487,
        "ground_name": "Iqra Games Village (IGV)",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "5060042",
        "winning_team": "Bengaluru Titans",
        "match_result": "Resulted",
        "win_by": "4 wickets",
        "team_a_id": 4438499,
        "team_a": "Fighters Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 5060042,
        "team_b": "Bengaluru Titans",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1700498455702_QFsEt9DDNj33.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "Je7ERz86PoE",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 1,
        "is_backend_match": 0,
        "latitude": 12.8868089,
        "longitude": 77.6431831,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 1,
        "index": 7,
        "match_summary": {
          "team_id": -1,
          "summary": "Bengaluru Titans won by 4 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "131/6",
        "team_a_innings": [
          {
            "team_id": 4438499,
            "inning": 1,
            "inning_start_time": "2023-10-27T01:09:54.000Z",
            "inning_end_time": "2023-10-27T02:34:36.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 131,
            "total_wicket": 6,
            "total_extra": 9,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "131/6",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.55"
            }
          }
        ],
        "team_b_summary": "132/6",
        "team_b_innings": [
          {
            "team_id": 5060042,
            "inning": 2,
            "inning_start_time": "2023-10-27T02:43:53.000Z",
            "inning_end_time": "2023-10-27T03:44:12.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 132,
            "total_wicket": 6,
            "total_extra": 4,
            "overs_played": "15.2",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "132/6",
              "over": "(15.2 Ov)",
              "ball": "(0 Ball)",
              "rr": "8.61"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to bat"
      },
      {
        "match_id": 8180478,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-10-21T01:34:10.000Z",
        "match_end_time": "2023-10-21 04:48:21",
        "created_date": "2023-10-18T06:20:02.000Z",
        "created_by": 17964824,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 533548,
        "ground_name": "Sports Craftsman Cricket Ground - One Dream",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "39 runs",
        "team_a_id": 4438499,
        "team_a": "Fighters Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4756306,
        "team_b": "Elite Dons",
        "team_b_logo": "https://media.cricheroes.in/default/teamintital/ED.png",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8545507,
        "longitude": 77.738949,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 8,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 39 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "213/6",
        "team_a_innings": [
          {
            "team_id": 4438499,
            "inning": 1,
            "inning_start_time": "2023-10-21T01:34:10.000Z",
            "inning_end_time": "2023-10-21T03:17:52.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 213,
            "total_wicket": 6,
            "total_extra": 16,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "213/6",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "10.65"
            }
          }
        ],
        "team_b_summary": "174/10",
        "team_b_innings": [
          {
            "team_id": 4756306,
            "inning": 2,
            "inning_start_time": "2023-10-21T03:23:54.000Z",
            "inning_end_time": "2023-10-21T04:48:19.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 174,
            "total_wicket": 10,
            "total_extra": 25,
            "overs_played": "19.4",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "174/10",
              "over": "(19.4 Ov)",
              "ball": "(0 Ball)",
              "rr": "8.85"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to bat"
      },
      {
        "match_id": 8170983,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-10-20T01:01:47.000Z",
        "match_end_time": "2023-10-20 03:24:52",
        "created_date": "2023-10-16T14:11:51.000Z",
        "created_by": 4412996,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 42487,
        "ground_name": "Iqra Games Village (IGV)",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "5 wickets",
        "team_a_id": 5060042,
        "team_a": "Bengaluru Titans",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1700498455702_QFsEt9DDNj33.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8868089,
        "longitude": 77.6431831,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 9,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 5 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "121/10",
        "team_a_innings": [
          {
            "team_id": 5060042,
            "inning": 1,
            "inning_start_time": "2023-10-20T01:01:47.000Z",
            "inning_end_time": "2023-10-20T02:24:25.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 121,
            "total_wicket": 10,
            "total_extra": 17,
            "overs_played": "19.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "121/10",
              "over": "(19.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.37"
            }
          }
        ],
        "team_b_summary": "125/5",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-10-20T02:28:27.000Z",
            "inning_end_time": "2023-10-20T03:24:51.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 125,
            "total_wicket": 5,
            "total_extra": 2,
            "overs_played": "12.4",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "125/5",
              "over": "(12.4 Ov)",
              "ball": "(0 Ball)",
              "rr": "9.87"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to field"
      },
      {
        "match_id": 8035759,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-10-08T01:51:49.000Z",
        "match_end_time": "2023-10-08 04:44:44",
        "created_date": "2023-10-02T15:25:50.000Z",
        "created_by": 4412996,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 12639,
        "ground_name": "Power Play",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "3675515",
        "winning_team": "Action Heros XI",
        "match_result": "Resulted",
        "win_by": "59 runs",
        "team_a_id": 3675515,
        "team_a": "Action Heros XI",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1690797432258_q6TqBK7hXud4.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.9753926,
        "longitude": 77.71311229999992,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 0,
        "match_summary": {
          "team_id": -1,
          "summary": "Action Heros XI won by 59 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "148/8",
        "team_a_innings": [
          {
            "team_id": 3675515,
            "inning": 1,
            "inning_start_time": "2023-10-08T01:51:49.000Z",
            "inning_end_time": "2023-10-08T03:20:19.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 148,
            "total_wicket": 8,
            "total_extra": 18,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "148/8",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "7.40"
            }
          }
        ],
        "team_b_summary": "89/10",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-10-08T03:28:05.000Z",
            "inning_end_time": "2023-10-08T04:44:39.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 89,
            "total_wicket": 10,
            "total_extra": 24,
            "overs_played": "17.2",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "89/10",
              "over": "(17.2 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.13"
            }
          }
        ],
        "toss_details": "Action Heros XI won the toss and elected to bat"
      },
      {
        "match_id": 8041605,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-10-07T01:50:11.000Z",
        "match_end_time": "2023-10-07 04:34:04",
        "created_date": "2023-10-03T13:38:17.000Z",
        "created_by": 10104696,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 504850,
        "ground_name": "Sports Craftsman Cricket Ground",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "54 runs",
        "team_a_id": 4438499,
        "team_a": "Fighters Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 3675515,
        "team_b": "Action Heros XI",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1690797432258_q6TqBK7hXud4.jpg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8610597,
        "longitude": 77.74326769999999,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 1,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 54 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "152/8",
        "team_a_innings": [
          {
            "team_id": 4438499,
            "inning": 1,
            "inning_start_time": "2023-10-07T01:50:11.000Z",
            "inning_end_time": "2023-10-07T03:09:22.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 152,
            "total_wicket": 8,
            "total_extra": 24,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "152/8",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "7.60"
            }
          }
        ],
        "team_b_summary": "98/10",
        "team_b_innings": [
          {
            "team_id": 3675515,
            "inning": 2,
            "inning_start_time": "2023-10-07T03:19:18.000Z",
            "inning_end_time": "2023-10-07T04:34:01.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 98,
            "total_wicket": 10,
            "total_extra": 11,
            "overs_played": "16.5",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "98/10",
              "over": "(16.5 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.82"
            }
          }
        ],
        "toss_details": "Action Heros XI won the toss and elected to field"
      },
      {
        "match_id": 8041664,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-10-06T01:05:33.000Z",
        "match_end_time": "2023-10-06 03:17:36",
        "created_date": "2023-10-03T13:48:34.000Z",
        "created_by": 4412996,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 42487,
        "ground_name": "Iqra Games Village (IGV)",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "5060042",
        "winning_team": "Bengaluru Titans",
        "match_result": "Resulted",
        "win_by": "3 wickets",
        "team_a_id": 4438499,
        "team_a": "Fighters Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 5060042,
        "team_b": "Bengaluru Titans",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1700498455702_QFsEt9DDNj33.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8868089,
        "longitude": 77.6431831,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 2,
        "match_summary": {
          "team_id": -1,
          "summary": "Bengaluru Titans won by 3 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "98/10",
        "team_a_innings": [
          {
            "team_id": 4438499,
            "inning": 1,
            "inning_start_time": "2023-10-06T01:05:33.000Z",
            "inning_end_time": "2023-10-06T02:15:58.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 98,
            "total_wicket": 10,
            "total_extra": 13,
            "overs_played": "18.2",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "98/10",
              "over": "(18.2 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.35"
            }
          }
        ],
        "team_b_summary": "99/7",
        "team_b_innings": [
          {
            "team_id": 5060042,
            "inning": 2,
            "inning_start_time": "2023-10-06T02:24:22.000Z",
            "inning_end_time": "2023-10-06T03:17:35.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 99,
            "total_wicket": 7,
            "total_extra": 9,
            "overs_played": "14.1",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "99/7",
              "over": "(14.1 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.99"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to bat"
      },
      {
        "match_id": 7997746,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2023-10-01T01:18:00.000Z",
        "match_end_time": "2023-10-01 03:25:32",
        "created_date": "2023-09-30T05:40:05.000Z",
        "created_by": 10419676,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 63760,
        "ground_name": "Nuance Sports Ground 4",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "7 wickets",
        "team_a_id": 3951333,
        "team_a": "Uber Lions",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1676620563528_yhCHmtO9VE5d.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "72134",
        "tournament_name": "NUANCE PRACTICE MATCHES",
        "tournament_category_id": "",
        "tournament_round_id": "126985",
        "tournament_round_name": "League Matches",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8594936,
        "longitude": 77.7464598,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 3,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 7 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "128/10",
        "team_a_innings": [
          {
            "team_id": 3951333,
            "inning": 1,
            "inning_start_time": "2023-10-01T01:18:00.000Z",
            "inning_end_time": "2023-10-01T02:33:18.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 128,
            "total_wicket": 10,
            "total_extra": 19,
            "overs_played": "19.5",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "128/10",
              "over": "(19.5 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.45"
            }
          }
        ],
        "team_b_summary": "133/3",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-10-01T02:37:19.000Z",
            "inning_end_time": "2023-10-01T03:25:30.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 133,
            "total_wicket": 3,
            "total_extra": 13,
            "overs_played": "12.4",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "133/3",
              "over": "(12.4 Ov)",
              "ball": "(0 Ball)",
              "rr": "10.50"
            }
          }
        ],
        "toss_details": "Uber Lions won the toss and elected to bat"
      },
      {
        "match_id": 7976080,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-09-30T01:34:40.000Z",
        "match_end_time": "2023-09-30 03:50:40",
        "created_date": "2023-09-27T17:45:35.000Z",
        "created_by": 4412996,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 190831,
        "ground_name": "CBT GREEN PARK",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "6 wickets",
        "team_a_id": 5006507,
        "team_a": "Terrific Eleven",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1693494488936_MkwuAbSBuqQm.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8732917,
        "longitude": 77.7288811,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 4,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 6 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "104/10",
        "team_a_innings": [
          {
            "team_id": 5006507,
            "inning": 1,
            "inning_start_time": "2023-09-30T01:34:40.000Z",
            "inning_end_time": "2023-09-30T02:55:50.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 104,
            "total_wicket": 10,
            "total_extra": 11,
            "overs_played": "17.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "104/10",
              "over": "(17.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.12"
            }
          }
        ],
        "team_b_summary": "105/4",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-09-30T02:56:09.000Z",
            "inning_end_time": "2023-09-30T03:50:37.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 105,
            "total_wicket": 4,
            "total_extra": 12,
            "overs_played": "13.1",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "105/4",
              "over": "(13.1 Ov)",
              "ball": "(0 Ball)",
              "rr": "7.97"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to field"
      },
      {
        "match_id": 7942826,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2023-09-24T07:52:08.000Z",
        "match_end_time": "2023-09-24 12:54:30",
        "created_date": "2023-09-24T01:24:54.000Z",
        "created_by": 10529436,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 13488,
        "ground_name": "TDR Sports Ground",
        "overs": 30,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "3806692",
        "winning_team": "Djca",
        "match_result": "Resulted",
        "win_by": "3 wickets",
        "team_a_id": 4438499,
        "team_a": "Fighters Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 3806692,
        "team_b": "Djca",
        "team_b_logo": "https://media.cricheroes.in/default/teamintital/DJ.png",
        "is_b_home_team": 0,
        "tournament_id": "549914",
        "tournament_name": "DSE PRACTICE MATCHES 2023",
        "tournament_category_id": "",
        "tournament_round_id": "993861",
        "tournament_round_name": "Super League",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8929664,
        "longitude": 77.739219,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 5,
        "match_summary": {
          "team_id": -1,
          "summary": "Djca won by 3 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "178/10",
        "team_a_innings": [
          {
            "team_id": 4438499,
            "inning": 1,
            "inning_start_time": "2023-09-24T07:52:08.000Z",
            "inning_end_time": "2023-09-24T10:31:37.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 178,
            "total_wicket": 10,
            "total_extra": 18,
            "overs_played": "28.5",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "178/10",
              "over": "(28.5 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.17"
            }
          }
        ],
        "team_b_summary": "182/7",
        "team_b_innings": [
          {
            "team_id": 3806692,
            "inning": 2,
            "inning_start_time": "2023-09-24T10:48:22.000Z",
            "inning_end_time": "2023-09-24T12:54:23.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 182,
            "total_wicket": 7,
            "total_extra": 15,
            "overs_played": "29.5",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "182/7",
              "over": "(29.5 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.10"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to bat"
      },
      {
        "match_id": 7921720,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-09-23T01:31:34.000Z",
        "match_end_time": "2023-09-23 03:56:08",
        "created_date": "2023-09-21T13:46:12.000Z",
        "created_by": 7664962,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 159753,
        "ground_name": "Coolulu Cricket grounds",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4334256",
        "winning_team": "Challengers Cricket Club",
        "match_result": "Resulted",
        "win_by": "4 wickets",
        "team_a_id": 4438499,
        "team_a": "Fighters Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4334256,
        "team_b": "Challengers Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1686076493889_KNkoGIfpiur0.jpg",
        "is_b_home_team": 0,
        "tournament_id": "363706",
        "tournament_name": "COOLULU PRACTICE MATCHES",
        "tournament_category_id": "",
        "tournament_round_id": "622106",
        "tournament_round_name": "Relegation Matches",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.9007815,
        "longitude": 77.7653125,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 6,
        "match_summary": {
          "team_id": -1,
          "summary": "Challengers Cricket Club won by 4 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "108/10",
        "team_a_innings": [
          {
            "team_id": 4438499,
            "inning": 1,
            "inning_start_time": "2023-09-23T01:31:34.000Z",
            "inning_end_time": "2023-09-23T02:46:26.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 108,
            "total_wicket": 10,
            "total_extra": 8,
            "overs_played": "19.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "108/10",
              "over": "(19.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.68"
            }
          }
        ],
        "team_b_summary": "110/5",
        "team_b_innings": [
          {
            "team_id": 4334256,
            "inning": 2,
            "inning_start_time": "2023-09-23T02:50:32.000Z",
            "inning_end_time": "2023-09-23T03:56:05.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 110,
            "total_wicket": 5,
            "total_extra": 16,
            "overs_played": "16.1",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "110/5",
              "over": "(16.1 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.80"
            }
          }
        ],
        "toss_details": "Challengers Cricket Club won the toss and elected to field"
      },
      {
        "match_id": 7879133,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "LEATHER",
        "current_inning": 2,
        "match_start_time": "2023-09-16T05:25:51.000Z",
        "match_end_time": "2023-09-16 09:09:02",
        "created_date": "2023-09-16T05:24:14.000Z",
        "created_by": 5949131,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 497725,
        "ground_name": "Power Play Cricket Ground 2",
        "overs": 30,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "3466923",
        "winning_team": "Red Bulls",
        "match_result": "Resulted",
        "win_by": "106 runs",
        "team_a_id": 3466923,
        "team_a": "Red Bulls",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1681315926613_5QZL2xiaoEmR.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8809974,
        "longitude": 77.7130835,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 7,
        "match_summary": {
          "team_id": -1,
          "summary": "Red Bulls won by 106 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "214/8",
        "team_a_innings": [
          {
            "team_id": 3466923,
            "inning": 1,
            "inning_start_time": "2023-09-16T05:25:51.000Z",
            "inning_end_time": "2023-09-16T07:41:15.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 214,
            "total_wicket": 8,
            "total_extra": 27,
            "overs_played": "30.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "214/8",
              "over": "(30.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "7.13"
            }
          }
        ],
        "team_b_summary": "108/11",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-09-16T07:41:42.000Z",
            "inning_end_time": "2023-09-16T09:08:52.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 108,
            "total_wicket": 11,
            "total_extra": 15,
            "overs_played": "18.1",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "108/11",
              "over": "(18.1 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.94"
            }
          }
        ],
        "toss_details": "Red Bulls won the toss and elected to bat"
      },
      {
        "match_id": 7868607,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-09-15T01:06:31.000Z",
        "match_end_time": "2023-09-15 03:22:11",
        "created_date": "2023-09-15T00:45:33.000Z",
        "created_by": 16191660,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 42487,
        "ground_name": "Iqra Games Village (IGV)",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "5 wickets",
        "team_a_id": 5060042,
        "team_a": "Bengaluru Titans",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1700498455702_QFsEt9DDNj33.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8868089,
        "longitude": 77.6431831,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 8,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 5 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "131/8",
        "team_a_innings": [
          {
            "team_id": 5060042,
            "inning": 1,
            "inning_start_time": "2023-09-15T01:06:31.000Z",
            "inning_end_time": "",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 131,
            "total_wicket": 8,
            "total_extra": 14,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "131/8",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.55"
            }
          }
        ],
        "team_b_summary": "132/5",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-09-15T02:23:00.000Z",
            "inning_end_time": "2023-09-15T03:22:10.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 132,
            "total_wicket": 5,
            "total_extra": 12,
            "overs_played": "16.2",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "132/5",
              "over": "(16.2 Ov)",
              "ball": "(0 Ball)",
              "rr": "8.08"
            }
          }
        ],
        "toss_details": "Bengaluru Titans won the toss and elected to bat"
      },
      {
        "match_id": 7677975,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-08-25T00:53:52.000Z",
        "match_end_time": "2023-08-25 03:37:30",
        "created_date": "2023-08-21T12:09:08.000Z",
        "created_by": 4412996,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 42487,
        "ground_name": "Iqra Games Village (IGV)",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4061705",
        "winning_team": "Greenbay Super kings",
        "match_result": "Resulted",
        "win_by": "18 runs",
        "team_a_id": 4061705,
        "team_a": "Greenbay Super kings",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1705423439952_0q4cLsriLyBq.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8868089,
        "longitude": 77.6431831,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 9,
        "match_summary": {
          "team_id": -1,
          "summary": "Greenbay Super kings won by 18 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "164/9",
        "team_a_innings": [
          {
            "team_id": 4061705,
            "inning": 1,
            "inning_start_time": "2023-08-25T00:53:52.000Z",
            "inning_end_time": "2023-08-25T02:15:49.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 164,
            "total_wicket": 9,
            "total_extra": 11,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "164/9",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "8.20"
            }
          }
        ],
        "team_b_summary": "146/10",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-08-25T02:21:13.000Z",
            "inning_end_time": "2023-08-25T03:37:29.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 146,
            "total_wicket": 10,
            "total_extra": 18,
            "overs_played": "19.3",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "146/10",
              "over": "(19.3 Ov)",
              "ball": "(0 Ball)",
              "rr": "7.49"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to field"
      },
      {
        "match_id": 7508033,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-08-03T00:55:26.000Z",
        "match_end_time": "2023-08-03 03:02:17",
        "created_date": "2023-08-01T15:12:52.000Z",
        "created_by": 4412996,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 42487,
        "ground_name": "Iqra Games Village (IGV)",
        "overs": 15,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "4 wickets",
        "team_a_id": 4061705,
        "team_a": "Greenbay Super kings",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1705423439952_0q4cLsriLyBq.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8868089,
        "longitude": 77.6431831,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 0,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 4 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "119/7",
        "team_a_innings": [
          {
            "team_id": 4061705,
            "inning": 1,
            "inning_start_time": "2023-08-03T00:55:26.000Z",
            "inning_end_time": "",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 119,
            "total_wicket": 7,
            "total_extra": 12,
            "overs_played": "15.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "119/7",
              "over": "(15.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "7.93"
            }
          }
        ],
        "team_b_summary": "120/6",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-08-03T02:11:00.000Z",
            "inning_end_time": "2023-08-03T03:02:16.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 120,
            "total_wicket": 6,
            "total_extra": 6,
            "overs_played": "12.2",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "120/6",
              "over": "(12.2 Ov)",
              "ball": "(0 Ball)",
              "rr": "9.73"
            }
          }
        ],
        "toss_details": "Greenbay Super kings won the toss and elected to bat"
      },
      {
        "match_id": 7450490,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-07-28T00:58:01.000Z",
        "match_end_time": "2023-07-28 03:24:37",
        "created_date": "2023-07-24T05:51:54.000Z",
        "created_by": 16191660,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 42487,
        "ground_name": "Iqra Games Village (IGV)",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4061705",
        "winning_team": "Greenbay Super kings",
        "match_result": "Resulted",
        "win_by": "4 wickets",
        "team_a_id": 4438499,
        "team_a": "Fighters Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4061705,
        "team_b": "Greenbay Super kings",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1705423439952_0q4cLsriLyBq.jpg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8868089,
        "longitude": 77.6431831,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 1,
        "match_summary": {
          "team_id": -1,
          "summary": "Greenbay Super kings won by 4 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "119/10",
        "team_a_innings": [
          {
            "team_id": 4438499,
            "inning": 1,
            "inning_start_time": "2023-07-28T00:58:01.000Z",
            "inning_end_time": "2023-07-28T02:15:47.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 119,
            "total_wicket": 10,
            "total_extra": 16,
            "overs_played": "18.3",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "119/10",
              "over": "(18.3 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.43"
            }
          }
        ],
        "team_b_summary": "123/6",
        "team_b_innings": [
          {
            "team_id": 4061705,
            "inning": 2,
            "inning_start_time": "2023-07-28T02:21:44.000Z",
            "inning_end_time": "2023-07-28T03:24:35.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 123,
            "total_wicket": 6,
            "total_extra": 14,
            "overs_played": "17.5",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "123/6",
              "over": "(17.5 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.90"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to bat"
      },
      {
        "match_id": 7370925,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-07-21T01:03:48.000Z",
        "match_end_time": "2023-07-21 03:55:05",
        "created_date": "2023-07-14T11:24:40.000Z",
        "created_by": 4412996,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 42487,
        "ground_name": "Iqra Games Village (IGV)",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "18 runs",
        "team_a_id": 4438499,
        "team_a": "Fighters Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4061705,
        "team_b": "Greenbay Super kings",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1705423439952_0q4cLsriLyBq.jpg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8868089,
        "longitude": 77.6431831,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 2,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 18 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "209/8",
        "team_a_innings": [
          {
            "team_id": 4438499,
            "inning": 1,
            "inning_start_time": "2023-07-21T01:03:48.000Z",
            "inning_end_time": "2023-07-21T02:22:44.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 209,
            "total_wicket": 8,
            "total_extra": 13,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "209/8",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "10.45"
            }
          }
        ],
        "team_b_summary": "191/7",
        "team_b_innings": [
          {
            "team_id": 4061705,
            "inning": 2,
            "inning_start_time": "2023-07-21T02:32:00.000Z",
            "inning_end_time": "2023-07-21T03:55:04.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 191,
            "total_wicket": 7,
            "total_extra": 35,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "191/7",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "9.55"
            }
          }
        ],
        "toss_details": "Greenbay Super kings won the toss and elected to field"
      },
      {
        "match_id": 7349465,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-07-15T01:06:00.000Z",
        "match_end_time": "2023-07-15 03:15:35",
        "created_date": "2023-07-10T08:27:57.000Z",
        "created_by": 752082,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 48520,
        "ground_name": "Nuance Sports Ground 2",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4334256",
        "winning_team": "Challengers Cricket Club",
        "match_result": "Resulted",
        "win_by": "98 runs",
        "team_a_id": 4334256,
        "team_a": "Challengers Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1686076493889_KNkoGIfpiur0.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "72134",
        "tournament_name": "NUANCE PRACTICE MATCHES",
        "tournament_category_id": "",
        "tournament_round_id": "126985",
        "tournament_round_name": "League Matches",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8928819,
        "longitude": 77.7393697,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 3,
        "match_summary": {
          "team_id": -1,
          "summary": "Challengers Cricket Club won by 98 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "126/7",
        "team_a_innings": [
          {
            "team_id": 4334256,
            "inning": 1,
            "inning_start_time": "2023-07-15T01:10:31.000Z",
            "inning_end_time": "2023-07-15T02:30:50.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 126,
            "total_wicket": 7,
            "total_extra": 17,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "126/7",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.30"
            }
          }
        ],
        "team_b_summary": "28/10",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-07-15T02:38:14.000Z",
            "inning_end_time": "2023-07-15T03:15:33.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 28,
            "total_wicket": 10,
            "total_extra": 4,
            "overs_played": "9.5",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "28/10",
              "over": "(9.5 Ov)",
              "ball": "(0 Ball)",
              "rr": "2.85"
            }
          }
        ],
        "toss_details": "Challengers Cricket Club won the toss and elected to bat"
      },
      {
        "match_id": 7329693,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-07-14T01:06:54.000Z",
        "match_end_time": "2023-07-14 03:27:41",
        "created_date": "2023-07-08T06:38:04.000Z",
        "created_by": 16191660,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 42487,
        "ground_name": "Iqra Games Village (IGV)",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "8 wickets",
        "team_a_id": 4061705,
        "team_a": "Greenbay Super kings",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1705423439952_0q4cLsriLyBq.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8868089,
        "longitude": 77.6431831,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 4,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 8 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "114/9",
        "team_a_innings": [
          {
            "team_id": 4061705,
            "inning": 1,
            "inning_start_time": "2023-07-14T01:06:54.000Z",
            "inning_end_time": "2023-07-14T02:24:12.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 114,
            "total_wicket": 9,
            "total_extra": 17,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "114/9",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.70"
            }
          }
        ],
        "team_b_summary": "117/2",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-07-14T02:33:41.000Z",
            "inning_end_time": "2023-07-14T03:27:40.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 117,
            "total_wicket": 2,
            "total_extra": 5,
            "overs_played": "16.4",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "117/2",
              "over": "(16.4 Ov)",
              "ball": "(0 Ball)",
              "rr": "7.02"
            }
          }
        ],
        "toss_details": "Greenbay Super kings won the toss and elected to bat"
      },
      {
        "match_id": 7267012,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-07-07T00:52:34.000Z",
        "match_end_time": "2023-07-07 03:14:27",
        "created_date": "2023-06-30T12:51:53.000Z",
        "created_by": 16191660,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 42487,
        "ground_name": "Iqra Games Village (IGV)",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4061705",
        "winning_team": "Greenbay Super kings",
        "match_result": "Resulted",
        "win_by": "7 wickets",
        "team_a_id": 4438499,
        "team_a": "Fighters Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4061705,
        "team_b": "Greenbay Super kings",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1705423439952_0q4cLsriLyBq.jpg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8868089,
        "longitude": 77.6431831,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 5,
        "match_summary": {
          "team_id": -1,
          "summary": "Greenbay Super kings won by 7 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "121/10",
        "team_a_innings": [
          {
            "team_id": 4438499,
            "inning": 1,
            "inning_start_time": "2023-07-07T00:52:34.000Z",
            "inning_end_time": "2023-07-07T02:09:11.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 121,
            "total_wicket": 10,
            "total_extra": 10,
            "overs_played": "19.5",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "121/10",
              "over": "(19.5 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.10"
            }
          }
        ],
        "team_b_summary": "122/3",
        "team_b_innings": [
          {
            "team_id": 4061705,
            "inning": 2,
            "inning_start_time": "2023-07-07T02:14:35.000Z",
            "inning_end_time": "2023-07-07T03:14:25.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 122,
            "total_wicket": 3,
            "total_extra": 11,
            "overs_played": "14.2",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "122/3",
              "over": "(14.2 Ov)",
              "ball": "(0 Ball)",
              "rr": "8.51"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to bat"
      },
      {
        "match_id": 7263063,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-07-02T01:13:00.000Z",
        "match_end_time": "2023-07-02 03:42:07",
        "created_date": "2023-06-30T02:59:18.000Z",
        "created_by": 752082,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 48520,
        "ground_name": "Nuance Sports Ground 2",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4334256",
        "winning_team": "Challengers Cricket Club",
        "match_result": "Resulted",
        "win_by": "6 wickets",
        "team_a_id": 4438499,
        "team_a": "Fighters Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4334256,
        "team_b": "Challengers Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1686076493889_KNkoGIfpiur0.jpg",
        "is_b_home_team": 0,
        "tournament_id": "72134",
        "tournament_name": "NUANCE PRACTICE MATCHES",
        "tournament_category_id": "",
        "tournament_round_id": "126985",
        "tournament_round_name": "League Matches",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8928819,
        "longitude": 77.7393697,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 6,
        "match_summary": {
          "team_id": -1,
          "summary": "Challengers Cricket Club won by 6 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "118/10",
        "team_a_innings": [
          {
            "team_id": 4438499,
            "inning": 1,
            "inning_start_time": "2023-07-02T01:17:39.000Z",
            "inning_end_time": "2023-07-02T02:25:48.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 118,
            "total_wicket": 10,
            "total_extra": 10,
            "overs_played": "19.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "118/10",
              "over": "(19.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.21"
            }
          }
        ],
        "team_b_summary": "120/4",
        "team_b_innings": [
          {
            "team_id": 4334256,
            "inning": 2,
            "inning_start_time": "2023-07-02T02:32:56.000Z",
            "inning_end_time": "2023-07-02T03:42:02.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 120,
            "total_wicket": 4,
            "total_extra": 12,
            "overs_played": "17.3",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "120/4",
              "over": "(17.3 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.86"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to bat"
      },
      {
        "match_id": 7244325,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-07-01T01:30:00.000Z",
        "match_end_time": "2023-07-01 04:31:56",
        "created_date": "2023-06-27T09:09:02.000Z",
        "created_by": 15429370,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 498852,
        "ground_name": "BH Cricket Ground",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4108026",
        "winning_team": "S2 Avanthika Cricket Club",
        "match_result": "Resulted",
        "win_by": "20 runs",
        "team_a_id": 4108026,
        "team_a": "S2 Avanthika Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1689202529473_Ne2OWplxfDNC.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.858279,
        "longitude": 77.762378,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 7,
        "match_summary": {
          "team_id": -1,
          "summary": "S2 Avanthika Cricket Club won by 20 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "149/7",
        "team_a_innings": [
          {
            "team_id": 4108026,
            "inning": 1,
            "inning_start_time": "2023-07-01T01:42:44.000Z",
            "inning_end_time": "2023-07-01T03:00:16.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 149,
            "total_wicket": 7,
            "total_extra": 20,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "149/7",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "7.45"
            }
          }
        ],
        "team_b_summary": "129/7",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-07-01T03:04:21.000Z",
            "inning_end_time": "2023-07-01T04:31:54.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 129,
            "total_wicket": 7,
            "total_extra": 13,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "129/7",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.45"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to field"
      },
      {
        "match_id": 7180394,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-06-30T00:47:59.000Z",
        "match_end_time": "2023-06-30 03:10:09",
        "created_date": "2023-06-20T12:45:15.000Z",
        "created_by": 16191660,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 42487,
        "ground_name": "Iqra Games Village (IGV)",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4061705",
        "winning_team": "Greenbay Super kings",
        "match_result": "Resulted",
        "win_by": "1 wicket",
        "team_a_id": 4438499,
        "team_a": "Fighters Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4061705,
        "team_b": "Greenbay Super kings",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1705423439952_0q4cLsriLyBq.jpg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8868089,
        "longitude": 77.6431831,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 8,
        "match_summary": {
          "team_id": -1,
          "summary": "Greenbay Super kings won by 1 wicket",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "87/10",
        "team_a_innings": [
          {
            "team_id": 4438499,
            "inning": 1,
            "inning_start_time": "2023-06-30T00:47:59.000Z",
            "inning_end_time": "2023-06-30T02:02:32.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 87,
            "total_wicket": 10,
            "total_extra": 13,
            "overs_played": "17.1",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "87/10",
              "over": "(17.1 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.07"
            }
          }
        ],
        "team_b_summary": "89/9",
        "team_b_innings": [
          {
            "team_id": 4061705,
            "inning": 2,
            "inning_start_time": "2023-06-30T02:04:44.000Z",
            "inning_end_time": "2023-06-30T03:10:08.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 89,
            "total_wicket": 9,
            "total_extra": 17,
            "overs_played": "17.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "89/9",
              "over": "(17.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.24"
            }
          }
        ],
        "toss_details": "Greenbay Super kings won the toss and elected to field"
      },
      {
        "match_id": 7255737,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-06-29T03:29:00.000Z",
        "match_end_time": "2023-06-29 05:20:58",
        "created_date": "2023-06-29T03:22:46.000Z",
        "created_by": 4412996,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 366409,
        "ground_name": "TDR Sports Arena",
        "overs": 12,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "5 wickets",
        "team_a_id": 4686543,
        "team_a": "CM",
        "team_a_logo": "https://media.cricheroes.in/default/teamintital/CM.png",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 0,
        "longitude": 0,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 9,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 5 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "118/2",
        "team_a_innings": [
          {
            "team_id": 4686543,
            "inning": 1,
            "inning_start_time": "2023-06-29T03:29:00.000Z",
            "inning_end_time": "2023-06-29T04:12:16.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 118,
            "total_wicket": 2,
            "total_extra": 8,
            "overs_played": "12.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "118/2",
              "over": "(12.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "9.83"
            }
          }
        ],
        "team_b_summary": "119/5",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-06-29T04:16:39.000Z",
            "inning_end_time": "2023-06-29T05:20:57.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 119,
            "total_wicket": 5,
            "total_extra": 9,
            "overs_played": "11.4",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "119/5",
              "over": "(11.4 Ov)",
              "ball": "(0 Ball)",
              "rr": "10.20"
            }
          }
        ],
        "toss_details": "CM won the toss and elected to bat"
      },
      {
        "match_id": 7237623,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-06-29T01:16:08.000Z",
        "match_end_time": "2023-06-29 03:18:31",
        "created_date": "2023-06-26T06:49:03.000Z",
        "created_by": 4412996,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 459215,
        "ground_name": "WCA Sports club",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "9 wickets",
        "team_a_id": 4686543,
        "team_a": "CM",
        "team_a_logo": "https://media.cricheroes.in/default/teamintital/CM.png",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8872237,
        "longitude": 77.7488312,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 0,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 9 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "100/8",
        "team_a_innings": [
          {
            "team_id": 4686543,
            "inning": 1,
            "inning_start_time": "2023-06-29T01:16:08.000Z",
            "inning_end_time": "2023-06-29T02:22:56.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 100,
            "total_wicket": 8,
            "total_extra": 12,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "100/8",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.00"
            }
          }
        ],
        "team_b_summary": "101/1",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-06-29T02:31:46.000Z",
            "inning_end_time": "2023-06-29T03:18:30.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 101,
            "total_wicket": 1,
            "total_extra": 12,
            "overs_played": "11.2",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "101/1",
              "over": "(11.2 Ov)",
              "ball": "(0 Ball)",
              "rr": "8.91"
            }
          }
        ],
        "toss_details": "CM won the toss and elected to bat"
      },
      {
        "match_id": 7181467,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-06-24T04:56:00.000Z",
        "match_end_time": "2023-06-24 08:34:10",
        "created_date": "2023-06-20T15:40:40.000Z",
        "created_by": 1723265,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 459215,
        "ground_name": "WCA Sports club",
        "overs": 25,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "3874644",
        "winning_team": "Spartans",
        "match_result": "Resulted",
        "win_by": "63 runs",
        "team_a_id": 3874644,
        "team_a": "Spartans",
        "team_a_logo": "https://media.cricheroes.in/default/teamintital/SP.png",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8872237,
        "longitude": 77.7488312,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 1,
        "match_summary": {
          "team_id": -1,
          "summary": "Spartans won by 63 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "196/8",
        "team_a_innings": [
          {
            "team_id": 3874644,
            "inning": 1,
            "inning_start_time": "2023-06-24T04:57:29.000Z",
            "inning_end_time": "2023-06-24T06:55:27.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 196,
            "total_wicket": 8,
            "total_extra": 25,
            "overs_played": "25.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "196/8",
              "over": "(25.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "7.84"
            }
          }
        ],
        "team_b_summary": "133/10",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-06-24T07:06:53.000Z",
            "inning_end_time": "2023-06-24T08:34:07.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 133,
            "total_wicket": 10,
            "total_extra": 24,
            "overs_played": "19.5",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "133/10",
              "over": "(19.5 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.71"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to field"
      },
      {
        "match_id": 7130623,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-06-23T00:55:20.000Z",
        "match_end_time": "2023-06-23 03:50:24",
        "created_date": "2023-06-16T06:44:00.000Z",
        "created_by": 16191660,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 42487,
        "ground_name": "Iqra Games Village (IGV)",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4438499",
        "winning_team": "Fighters Cricket Club",
        "match_result": "Resulted",
        "win_by": "1 run",
        "team_a_id": 4438499,
        "team_a": "Fighters Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4061705,
        "team_b": "Greenbay Super kings",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1705423439952_0q4cLsriLyBq.jpg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8868089,
        "longitude": 77.6431831,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 2,
        "match_summary": {
          "team_id": -1,
          "summary": "Fighters Cricket Club won by 1 run",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "134/9",
        "team_a_innings": [
          {
            "team_id": 4438499,
            "inning": 1,
            "inning_start_time": "2023-06-23T00:55:20.000Z",
            "inning_end_time": "2023-06-23T02:12:09.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 134,
            "total_wicket": 9,
            "total_extra": 10,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "134/9",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.70"
            }
          }
        ],
        "team_b_summary": "133/6",
        "team_b_innings": [
          {
            "team_id": 4061705,
            "inning": 2,
            "inning_start_time": "2023-06-23T02:23:36.000Z",
            "inning_end_time": "2023-06-23T03:50:24.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 133,
            "total_wicket": 6,
            "total_extra": 22,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "133/6",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.65"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to bat"
      },
      {
        "match_id": 7109083,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-06-18T01:28:49.000Z",
        "match_end_time": "2023-06-18 04:23:24",
        "created_date": "2023-06-13T14:49:32.000Z",
        "created_by": 16977491,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 459215,
        "ground_name": "WCA Sports club",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4503639",
        "winning_team": "SwAG Tigers",
        "match_result": "Resulted",
        "win_by": "38 runs",
        "team_a_id": 4503639,
        "team_a": "SwAG Tigers",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1684337846076_18Wy0B5ZKwcV.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8872237,
        "longitude": 77.7488312,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 3,
        "match_summary": {
          "team_id": -1,
          "summary": "SwAG Tigers won by 38 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "160/7",
        "team_a_innings": [
          {
            "team_id": 4503639,
            "inning": 1,
            "inning_start_time": "2023-06-18T01:28:49.000Z",
            "inning_end_time": "2023-06-18T02:53:30.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 160,
            "total_wicket": 7,
            "total_extra": 14,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "160/7",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "8.00"
            }
          }
        ],
        "team_b_summary": "122/9",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-06-18T03:01:49.000Z",
            "inning_end_time": "2023-06-18T04:23:24.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 122,
            "total_wicket": 9,
            "total_extra": 19,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "122/9",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.10"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to field"
      },
      {
        "match_id": 7108903,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-06-16T00:43:55.000Z",
        "match_end_time": "2023-06-16 03:35:44",
        "created_date": "2023-06-13T14:29:27.000Z",
        "created_by": 16191660,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 42487,
        "ground_name": "Iqra Games Village (IGV)",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4061705",
        "winning_team": "Greenbay Super kings",
        "match_result": "Resulted",
        "win_by": "11 runs",
        "team_a_id": 4061705,
        "team_a": "Greenbay Super kings",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1705423439952_0q4cLsriLyBq.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8868089,
        "longitude": 77.6431831,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 4,
        "match_summary": {
          "team_id": -1,
          "summary": "Greenbay Super kings won by 11 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "148/6",
        "team_a_innings": [
          {
            "team_id": 4061705,
            "inning": 1,
            "inning_start_time": "2023-06-16T00:43:55.000Z",
            "inning_end_time": "2023-06-16T02:13:59.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 148,
            "total_wicket": 6,
            "total_extra": 12,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "148/6",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "7.40"
            }
          }
        ],
        "team_b_summary": "137/10",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-06-16T02:18:40.000Z",
            "inning_end_time": "2023-06-16T03:35:43.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 137,
            "total_wicket": 10,
            "total_extra": 12,
            "overs_played": "19.1",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "137/10",
              "over": "(19.1 Ov)",
              "ball": "(0 Ball)",
              "rr": "7.15"
            }
          }
        ],
        "toss_details": "Greenbay Super kings won the toss and elected to bat"
      },
      {
        "match_id": 7016811,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-06-09T00:45:20.000Z",
        "match_end_time": "2023-06-09 03:09:22",
        "created_date": "2023-06-05T10:35:31.000Z",
        "created_by": 16191660,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 42487,
        "ground_name": "Iqra Games Village (IGV)",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "4061705",
        "winning_team": "Greenbay Super kings",
        "match_result": "Resulted",
        "win_by": "5 wickets",
        "team_a_id": 4438499,
        "team_a": "Fighters Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 4061705,
        "team_b": "Greenbay Super kings",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1705423439952_0q4cLsriLyBq.jpg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8868089,
        "longitude": 77.6431831,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 5,
        "match_summary": {
          "team_id": -1,
          "summary": "Greenbay Super kings won by 5 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "105/10",
        "team_a_innings": [
          {
            "team_id": 4438499,
            "inning": 1,
            "inning_start_time": "2023-06-09T00:45:20.000Z",
            "inning_end_time": "2023-06-09T02:04:10.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 105,
            "total_wicket": 10,
            "total_extra": 13,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "105/10",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "5.25"
            }
          }
        ],
        "team_b_summary": "106/5",
        "team_b_innings": [
          {
            "team_id": 4061705,
            "inning": 2,
            "inning_start_time": "2023-06-09T02:08:49.000Z",
            "inning_end_time": "2023-06-09T03:09:21.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 106,
            "total_wicket": 5,
            "total_extra": 9,
            "overs_played": "16.1",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "106/5",
              "over": "(16.1 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.56"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to bat"
      },
      {
        "match_id": 6946978,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-06-04T02:11:34.000Z",
        "match_end_time": "2023-06-04 05:07:06",
        "created_date": "2023-05-31T07:15:11.000Z",
        "created_by": 1973950,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 318126,
        "ground_name": "HCMR Ground Halanayakanahalli",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "2651702",
        "winning_team": "Mana Tropicale Cricket Club",
        "match_result": "Resulted",
        "win_by": "63 runs",
        "team_a_id": 2651702,
        "team_a": "Mana Tropicale Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1653548049999_9lKHNrTbMvp3.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8994106,
        "longitude": 77.686612,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 6,
        "match_summary": {
          "team_id": -1,
          "summary": "Mana Tropicale Cricket Club won by 63 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "184/10",
        "team_a_innings": [
          {
            "team_id": 2651702,
            "inning": 1,
            "inning_start_time": "2023-06-04T02:11:34.000Z",
            "inning_end_time": "2023-06-04T03:46:54.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 184,
            "total_wicket": 10,
            "total_extra": 40,
            "overs_played": "19.1",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "184/10",
              "over": "(19.1 Ov)",
              "ball": "(0 Ball)",
              "rr": "9.60"
            }
          }
        ],
        "team_b_summary": "121/10",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-06-04T03:54:07.000Z",
            "inning_end_time": "2023-06-04T05:07:05.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 121,
            "total_wicket": 10,
            "total_extra": 9,
            "overs_played": "17.2",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "121/10",
              "over": "(17.2 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.98"
            }
          }
        ],
        "toss_details": "Mana Tropicale Cricket Club won the toss and elected to bat"
      },
      {
        "match_id": 6830401,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-05-21T02:03:32.000Z",
        "match_end_time": "2023-05-21 04:44:31",
        "created_date": "2023-05-21T01:54:51.000Z",
        "created_by": 4412996,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 482784,
        "ground_name": "WCA Ground",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "2702633",
        "winning_team": "Juggernaut 2.O",
        "match_result": "Resulted",
        "win_by": "5 wickets",
        "team_a_id": 4438499,
        "team_a": "Fighters Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_a_home_team": 0,
        "team_b_id": 2702633,
        "team_b": "Juggernaut 2.O",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1700035658460_Nw9ktTVpXLWh.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.9773128,
        "longitude": 77.7599994,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 7,
        "match_summary": {
          "team_id": -1,
          "summary": "Juggernaut 2.O won by 5 wickets",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "153/6",
        "team_a_innings": [
          {
            "team_id": 4438499,
            "inning": 1,
            "inning_start_time": "2023-05-21T02:03:32.000Z",
            "inning_end_time": "2023-05-21T03:19:09.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 153,
            "total_wicket": 6,
            "total_extra": 12,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "153/6",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "7.65"
            }
          }
        ],
        "team_b_summary": "156/5",
        "team_b_innings": [
          {
            "team_id": 2702633,
            "inning": 2,
            "inning_start_time": "2023-05-21T03:27:24.000Z",
            "inning_end_time": "2023-05-21T04:44:30.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 156,
            "total_wicket": 5,
            "total_extra": 19,
            "overs_played": "18.4",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "156/5",
              "over": "(18.4 Ov)",
              "ball": "(0 Ball)",
              "rr": "8.36"
            }
          }
        ],
        "toss_details": "Fighters Cricket Club won the toss and elected to bat"
      },
      {
        "match_id": 6750110,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-05-14T02:17:58.000Z",
        "match_end_time": "2023-05-14 05:15:35",
        "created_date": "2023-05-14T01:52:02.000Z",
        "created_by": 5905559,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 247024,
        "ground_name": "Red Toss Cricket",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "1913113",
        "winning_team": "Silver Sports Cricket Club",
        "match_result": "Resulted",
        "win_by": "18 runs",
        "team_a_id": 1913113,
        "team_a": "Silver Sports Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1640787653001_qtcEBoLXNuHu.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters Cricket Club",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.8993355,
        "longitude": 77.6865196,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 8,
        "match_summary": {
          "team_id": -1,
          "summary": "Silver Sports Cricket Club won by 18 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "216/9",
        "team_a_innings": [
          {
            "team_id": 1913113,
            "inning": 1,
            "inning_start_time": "2023-05-14T02:17:58.000Z",
            "inning_end_time": "2023-05-14T03:44:58.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 216,
            "total_wicket": 9,
            "total_extra": 19,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "216/9",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "10.80"
            }
          }
        ],
        "team_b_summary": "198/9",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-05-14T03:45:26.000Z",
            "inning_end_time": "2023-05-14T05:15:34.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 198,
            "total_wicket": 9,
            "total_extra": 9,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "198/9",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "9.90"
            }
          }
        ],
        "toss_details": "Silver Sports Cricket Club won the toss and elected to bat"
      },
      {
        "match_id": 6678070,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-05-07T05:15:29.000Z",
        "match_end_time": "2023-05-07 06:50:22",
        "created_date": "2023-05-07T05:14:25.000Z",
        "created_by": 1159674,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 177690,
        "ground_name": "wing cricket academy",
        "overs": 10,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "1913113",
        "winning_team": "Silver Sports Cricket Club",
        "match_result": "Resulted",
        "win_by": "11 runs",
        "team_a_id": 1913113,
        "team_a": "Silver Sports Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1640787653001_qtcEBoLXNuHu.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.7945737,
        "longitude": 77.7372411,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 9,
        "match_summary": {
          "team_id": -1,
          "summary": "Silver Sports Cricket Club won by 11 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "110/5",
        "team_a_innings": [
          {
            "team_id": 1913113,
            "inning": 1,
            "inning_start_time": "2023-05-07T05:15:29.000Z",
            "inning_end_time": "2023-05-07T05:54:46.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 110,
            "total_wicket": 5,
            "total_extra": 19,
            "overs_played": "10.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "110/5",
              "over": "(10.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "11.00"
            }
          }
        ],
        "team_b_summary": "99/6",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-05-07T06:00:48.000Z",
            "inning_end_time": "2023-05-07T06:50:22.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 99,
            "total_wicket": 6,
            "total_extra": 5,
            "overs_played": "10.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "99/6",
              "over": "(10.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "9.90"
            }
          }
        ],
        "toss_details": "Silver Sports Cricket Club won the toss and elected to bat"
      },
      {
        "match_id": 6672888,
        "match_type": "Limited Overs",
        "is_super_over": 0,
        "match_event_type": "",
        "match_event": "",
        "match_type_id": 1,
        "match_inning": 1,
        "ball_type": "TENNIS",
        "current_inning": 2,
        "match_start_time": "2023-05-07T02:11:00.000Z",
        "match_end_time": "2023-05-07 05:02:51",
        "created_date": "2023-05-07T02:08:05.000Z",
        "created_by": 4758235,
        "city_id": 1798,
        "city_name": "Bengaluru (Bangalore)",
        "ground_id": 177690,
        "ground_name": "wing cricket academy",
        "overs": 20,
        "balls": 0,
        "over_reduce": "",
        "is_dl": 0,
        "is_vjd": 0,
        "type": 3,
        "status": "past",
        "winning_team_id": "1913113",
        "winning_team": "Silver Sports Cricket Club",
        "match_result": "Resulted",
        "win_by": "61 runs",
        "team_a_id": 1913113,
        "team_a": "Silver Sports Cricket Club",
        "team_a_logo": "https://media.cricheroes.in/team_logo/1640787653001_qtcEBoLXNuHu.jpg",
        "is_a_home_team": 0,
        "team_b_id": 4438499,
        "team_b": "Fighters",
        "team_b_logo": "https://media.cricheroes.in/team_logo/1702991649898_MfmsHyTbICKV.jpeg",
        "is_b_home_team": 0,
        "tournament_id": "",
        "tournament_name": "",
        "tournament_category_id": "",
        "tournament_round_id": "",
        "tournament_round_name": "",
        "association_id": null,
        "association_year_id": null,
        "association_name": "",
        "association_logo": "",
        "steaming_url": "",
        "is_ticker": 0,
        "is_enable_tournament_streaming": 0,
        "is_enable_match_streaming": 0,
        "is_video_analyst": 0,
        "is_backend_match": 0,
        "latitude": 12.7945737,
        "longitude": 77.7372411,
        "is_fake_match": 0,
        "is_live_match_enable_in_web": 1,
        "is_live_match_enable_in_app": 1,
        "match_category_name": "",
        "is_watch_live": 0,
        "index": 0,
        "match_summary": {
          "team_id": -1,
          "summary": "Silver Sports Cricket Club won by 61 runs",
          "short_summary": "",
          "full_summary": "",
          "rrr": "0.00",
          "target": "-"
        },
        "team_a_summary": "165/8",
        "team_a_innings": [
          {
            "team_id": 1913113,
            "inning": 1,
            "inning_start_time": "2023-05-07T02:11:37.000Z",
            "inning_end_time": "2023-05-07T03:50:18.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 165,
            "total_wicket": 8,
            "total_extra": 26,
            "overs_played": "20.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "165/8",
              "over": "(20.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "8.25"
            }
          }
        ],
        "team_b_summary": "104/10",
        "team_b_innings": [
          {
            "team_id": 4438499,
            "inning": 2,
            "inning_start_time": "2023-05-07T03:57:42.000Z",
            "inning_end_time": "2023-05-07T05:02:50.000Z",
            "is_declare": 0,
            "is_forfeited": 0,
            "is_followon": 0,
            "total_run": 104,
            "total_wicket": 10,
            "total_extra": 13,
            "overs_played": "16.0",
            "balls_played": 0,
            "revised_target": 0,
            "revised_overs": 0,
            "lead_by": 0,
            "trail_by": 0,
            "summary": {
              "score": "104/10",
              "over": "(16.0 Ov)",
              "ball": "(0 Ball)",
              "rr": "6.50"
            }
          }
        ],
        "toss_details": "Silver Sports Cricket Club won the toss and elected to bat"
      }
    ]
  },
  reducers: {
    get: (state) =>{
      
    }
  },
})

// Action creators are generated for each case reducer function
export const { get} = matchList.actions

export default matchList.reducer