import { createSlice } from '@reduxjs/toolkit'

export const tournamentList = createSlice({
  name: 'tournamentList',
  initialState: {
    list: [
      {
        "tournament_id": 4,
        "tournament_name": "JMR one day T20 cup",
        "result": "WINNNERS",
    },
    {
      "tournament_id": 3,
      "tournament_name": "Cricket clash championship edition 4",
      "result": "WINNNERS",
  },
  {
    "tournament_id": 2,
    "tournament_name": "Gattahalli cricket tournmnet season 2",
    "result": "RUNNERS",
},
{
  "tournament_id": 1,
  "tournament_name": "Society champions league season 1",
  "result": "WINNNERS",
},
    ]
  },
  reducers: {
    get: (state) =>{
      
    }
  },
})

// Action creators are generated for each case reducer function
export const { get} = tournamentList.actions

export default tournamentList.reducer