import '../App.css';

function CompleteGallery() {
    return (
        <div>
            {/* <!-- Gallery Start --> */}
            <section className="gallery p-40">
                <div className="container">
                    <div className="heading">
                        <div className="left">
                            <h2 className="light-black">Our Latest Gallery</h2>
                            <p className="light-black">Step into Glory: The Fighters Cricket Club Gallery Unveiled!
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-12 mb-24">
                            <img src="/assets/media/gallery/39.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24">
                            <img src="/assets/media/gallery/38.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24">
                            <img src="/assets/media/gallery/37.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24">
                            <img src="/assets/media/gallery/36.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24">
                            <img src="/assets/media/gallery/25.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24">
                            <img src="/assets/media/gallery/34.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24 ">
                            <img src="/assets/media/gallery/33.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24 ">
                            <img src="/assets/media/gallery/32.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12  mb-24">
                            <img src="/assets/media/gallery/31.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24">
                            <img src="/assets/media/gallery/30.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24">
                            <img src="/assets/media/gallery/29.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24">
                            <img src="/assets/media/gallery/28.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24 ">
                            <img src="/assets/media/gallery/27.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24 ">
                            <img src="/assets/media/gallery/26.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12  mb-24">
                            <img src="/assets/media/gallery/35.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24">
                            <img src="/assets/media/gallery/24.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24">
                            <img src="/assets/media/gallery/23.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24">
                            <img src="/assets/media/gallery/22.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24 ">
                            <img src="/assets/media/gallery/20.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12  mb-24">
                            <img src="/assets/media/gallery/19.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24">
                            <img src="/assets/media/gallery/18.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24">
                            <img src="/assets/media/gallery/17.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24">
                            <img src="/assets/media/gallery/16.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24 ">
                            <img src="/assets/media/gallery/15.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24 ">
                            <img src="/assets/media/gallery/14.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12  mb-24">
                            <img src="/assets/media/gallery/13.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24">
                            <img src="/assets/media/gallery/12.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24">
                            <img src="/assets/media/gallery/11.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24">
                            <img src="/assets/media/gallery/10.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24 ">
                            <img src="/assets/media/gallery/9.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24 ">
                            <img src="/assets/media/gallery/8.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24">
                            <img src="/assets/media/gallery/7.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24">
                            <img src="/assets/media/gallery/6.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24">
                            <img src="/assets/media/gallery/5.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24">
                            <img src="/assets/media/gallery/4.jpeg" className="br-20 shadow" alt="" />
                        </div>
                        <div className="col-lg-6 col-12 mb-24 ">
                            <img src="/assets/media/gallery/3.jpeg" className="br-20 shadow" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Gallery End --> */}
        </div>
    );
}

export default CompleteGallery;