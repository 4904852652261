import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import store from './app/store'
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Team from './pages/Team';
import Gallery from './pages/Gallery';
import Home from './pages/Home';
import PlayerDetail from './pages/PlayerDetail';
import Matches from './pages/Matches';
import Tournaments from './pages/Tournaments';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/team",
    element: <Team />,
  },
  {
    path: "/gallery",
    element: <Gallery />,
  },
  {
    path: "/pd/:id/:name",
    element: <PlayerDetail />,
  },
  {
    path: "/matches",
    element: <Matches />,
  },
  {
    path: "/tournaments",
    element: <Tournaments />,
  }
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
    <RouterProvider router={router} />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
