import { Link } from "react-router-dom";

function AppFooter() {
    return (
        <div>
            {/* <!-- Footer Area Start  --> */}
            <footer >
                <div className="newslatter p-40 bg-green">
                    <div className="container">
                        <div className="content">
                            {/* <h4 className="color-white">Subscribe to our Newslatter!</h4>
                                <form action="index.html">
                                    <div className="search">
                                        <input name="Email" placeholder="Your Email" type="text"/>
                                        <button type="submit" className="cus-btn dark">Subscribe</button>
                                    </div>
                                </form> */}
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="footer-main mb-64">
                        <div className="footer-info">
                            <div className="footer-about">
                                <div className="logo">
                                    <a href="/">
                                        <img src="/assets/media/logo.png" alt="" />
                                    </a>
                                </div>

                            </div>
                            <div>
                                <p className="light-black">
                                    {/* <h4>Fighters Cricket Club</h4>  */}
                                    <br /><br /><br /> Fueling the passion, igniting the spirit.<br /> where every innings is a battle, <br />and victory is the sweetest melody.  </p>
                            </div>
                            <div><p className="light-black">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p></div>
                            <div className="menu ">
                                <h5 className="light-black">MENU</h5>
                                <ul className="link unstyled">
                                    <li className="mb-16">
                                        <Link to="/"><p className="dark-gray">Home</p></Link>
                                    </li>
                                    <li className="mb-16">
                                        <Link to="/team"><p className="dark-gray">Team</p></Link>
                                    </li>
                                    <li className="">
                                        <Link to="/gallery"><p className="dark-gray">Gallery</p></Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="contact">
                                <h5 className="light-black">CONTACT US</h5>
                                <ul className="contact-list unstyled">
                                    <li className="mb-16"> <i className="fal fa-map-marker-alt"></i> <h6>Bengaluru, India.</h6>
                                    </li>
                                    {/* <li className="mb-16">
                                            <a href="tel:0123456789"> <i className="fal fa-phone-alt"></i> <span>+1 233 898 0897</span></a>
                                        </li> */}
                                    <li className="">
                                        <a href="mailto:mail@fighterscricket.club"><i className="fal fa-envelope"></i>  <span>email@fighterscricket.club</span></a>
                                    </li>
                                </ul>
                            </div>
                            {/* <div className="social">
                                    <h5 className="light-black ">FOLLOW US!</h5>
                                    <ul className="unstyled social-icons">
                                        <li><a href="./index.html"><img src="assets/media/icons/Facebook.png" alt=""/></a></li>
                                        <li><a href="./index.html"><img src="assets/media/icons/Twitter.png" alt=""/></a></li>
                                        <li><a href="./index.html"><img src="assets/media/icons/Instagram.png" alt=""/></a></li>
                                        <li><a href="./index.html"><img src="assets/media/icons/Linkedin.png" alt=""/></a></li>
                                    </ul>
                                </div> */}
                        </div>
                    </div>
                    <p className="dark-gray text-center mb-16">©2024 Fighters Cricket Club (FCC) All Rights Reserved.</p>
                </div>
            </footer>
            {/* <!-- Footer Area End  --> */}
        </div>
    );
}

export default AppFooter;
