import { Link } from 'react-router-dom';
import '../App.css';
import { useState } from 'react';

interface Props {
    activeMenu?: string;
}

function AppHeader(props: Props) {

    const [hamburgerOpen, setHamburgerOpen] = useState(false);


    const toggleHamburger = () => {
        setHamburgerOpen(!hamburgerOpen);
    }

    return (
        <div>
            {/* <!-- Header Area Start  --> */}
            <header className="large-screens">
                <div className="container">
                    <nav className="navbar navbar-expand-lg p-0">
                        <div className="collapse navbar-collapse" id="mynavbar">
                            <div className="col-lg-8">
                                <div className="left-nav">
                                    <Link className="navbar-brand m-0 p-0" to="/"><img alt="" className="mw-100p" src="/assets/media/logo.png" /></Link>

                                    <ul className="navbar-nav m-0">
                                        <li className="menu-item">
                                            <Link className={props.activeMenu === "home" ? "active" : ""} to="/">Home</Link>
                                        </li>
                                        <li className="menu-item">
                                            <Link className={props.activeMenu === "team" ? "active" : ""} to="/team">Team</Link>
                                        </li>
                                        <li className="menu-item">
                                            <Link className={props.activeMenu === "gallery" ? "active" : ""} to="/gallery">Gallery</Link>
                                        </li>
                                        {/* <li className="menu-item">
                                            <Link className={props.activeMenu==="gallery"?"active":""} to="/gallery">Tournaments</Link>
                                        </li> */}
                                        <li className="menu-item">
                                            <li><Link className={props.activeMenu === "tournaments" ? "active" : ""} to="/tournaments">Tournaments</Link></li>
                                        </li>
                                        <li className="menu-item">
                                            <li><Link className={props.activeMenu === "matches" ? "active" : ""} to="/matches">Matches</Link></li>
                                        </li>



                                        {/* <li className="has-children">
                                            <a href="javascript:void(0);">More</a>
                                            <ul className="submenu">
                                                <li><Link className={props.activeMenu==="tournaments"?"active":""} to="/tournaments">Tournaments</Link></li>
                                                <li><Link className={props.activeMenu==="matches"?"active":""} to="/matches">Matches</Link></li>
                                            </ul>
                                        </li> */}

                                        {/* <li className="has-children">
                                            <a href="javascript:void(0);">Pages</a>
                                            <ul className="submenu">
                                                <li><a href="blog.html">blog</a></li>
                                                <li><a href="blog-detail.html">blog
                                                        detail </a></li>
                                                <li><a href="gallery.html">Gallery</a></li>
                                                <li><a href="team.html">Team
                                                    </a></li>
                                                <li><a href="player-detail.html">Player
                                                        Detail</a></li>
                                                <li><a href="match-result.html">Match
                                                        Result</a></li>
                                                <li><a
                                                        href="match-schedule.html">Match
                                                        Schedule</a></li>
                                                <li><a href="faq.html">FAQ's</a></li>
                                            </ul>
                                        </li>
                                        <li className="menu-item"><a href="match-schedule.html">TOURNAMENTS</a></li>
                                        <li className="menu-item"><a
                                                href="contact.html">CONTACT US</a></li> */}
                                    </ul>
                                </div>
                            </div>
                            {/* <div className="col-lg-4">
                                <form action="index.html"
                                    className="input-group search-bar">
                                    <input type="text" placeholder="Search..." required />
                                    <button className="search" type="submit"><i
                                            className="far fa-search search-icon"></i></button>
                                </form>
                            </div> */}
                        </div>
                    </nav>
                </div>
            </header>
            <header className="small-screen">
                <div className="container-fluid">
                    <div className="mobile-menu">
                        <div>
                            <a className="navbar-brand" href="/"><img alt=""
                                src="/assets/media/logo.png" /></a>
                        </div>
                        <div className="hamburger-menu" onClick={toggleHamburger}>
                            <div className="bar" onClick={toggleHamburger}></div>
                        </div>
                    </div>
                    {hamburgerOpen &&

                        <nav className="mobile-navar d-xl-none">
                            <ul>
                                <li><Link className={props.activeMenu === "home" ? "active" : ""} to="/">Home</Link></li>
                                <li><Link className={props.activeMenu === "team" ? "active" : ""} to="/team">Team</Link></li>
                                <li><Link className={props.activeMenu === "gallery" ? "active" : ""} to="/gallery">Gallery</Link></li>
                            </ul>
                        </nav>
                    }

                </div>
            </header>
            {/* <!-- Header Area End  --> */}
        </div>
    );
}

export default AppHeader;








