import AppFooter from '../components/AppFooter';
import AppHeader from '../components/AppHeader';
import AppModal from '../components/AppModal';
import HomeAchievements from '../components/HomeAchievements';
import HomeBanner from '../components/HomeBanner';
import HomeBlogs from '../components/HomeBlogs';
import HomeGallery from '../components/HomeGallery';
import HomeMatchResults from '../components/HomeMatchResults';
import HomeMiddleBanner from '../components/HomeMiddleBanner';

function Home() {
  return (
    <div className="">
      {/* <!-- Back To Top Start --> */}
      <a href="#main-wrapper" id="backto-top" className="back-to-top"><i
        className="fas fa-angle-up"></i></a>
      {/* <!-- Main Wrapper Start --> */}
      <div id="main-wrapper" className="main-wrapper overflow-hidden">
        <AppHeader  activeMenu='home' />
        <HomeBanner />
        {/* <!-- Main Content Start --> */}
        <div className="page-content">
          <HomeMatchResults showAll={false}/>
          <HomeAchievements />
          <HomeGallery />
          <HomeMiddleBanner />
          <HomeBlogs />
        </div>
        <AppFooter />
        <AppModal />
      </div>
    </div>
  );
}

export default Home;
