import '../App.css';

function HomeMiddleBanner() {
    return (
        <div>
            {/* <!-- join Start --> */}
            <section className="join m-40">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-12 offset-lg-6">
                                <div className="content">
                                    <h2 className="color-white text-xl-center">Home of the fearless!</h2>
                                    <h5 className="color-white text-xl-center">where passion meets performance, and each player is a warrior on the field. Fighters Cricket Club is not just a team. It's a community of like-minded individuals who share a love for the game and a relentless pursuit of excellence.</h5>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- join End --> */}
        </div>
    );
}

export default HomeMiddleBanner;