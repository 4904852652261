import '../App.css';

function HomeBanner() {
    return (
        <div>
            {/* <!-- Hero Banner start --> */}
            <div className="hero-banner-1">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="content">
                                <h1 className="mb-12 light-black">Fueling the passion, igniting the spirit.</h1>
                                <p className="mb-32 light-black ">Welcome to Fighters Cricket Club, <br />Where every innings is a battle, and victory is the sweetest melody.<br /> Unleashing our inner warrior on the pitch, where determination meets sportsmanship, and every boundary is a triumph.<br /> The roar of the Fighters echoes louder than the sound of the ball hitting the stumps. <br />Where the game is fierce, and the camaraderie is unbeatable. We play, We fight, We win together!"</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="banner-image">
                                <img src="/assets/media/banner/side-image.png"
                                    alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Hero Banner End --> */}
        </div>
    );
}

export default HomeBanner;