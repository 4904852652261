import { configureStore } from '@reduxjs/toolkit'
import playerList from '../features/profile/playerList'
import matchList from '../features/profile/matchList'
import tournamentList from '../features/profile/tournamentList'

export default configureStore({
  reducer: {
    playerList: playerList,
    matchList: matchList,
    tournamentList:tournamentList,
  },
})