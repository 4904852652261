import { createSlice } from '@reduxjs/toolkit'

export const playerList = createSlice({
  name: 'playerList',
  initialState: {
    list: [
      {
        fullName: "Bibin Sebastian",
        dateOfBirth: "02/05/1989",
        placeOfBirth: "Wayanad, Sultan Bathery",
        height: "5 ft 4 in (163 cm)",
        role: "All Rounder",
        battingStyle: "Right hand bat",
        bowlingStyle: "Right arm off break",
        nickName: "Captain Cool",
        playingStyle: "Classist",
        id: "8cb7ef79-78d6-4e50-b637-c5f81d87834a",
        crickId: "",
        fieldingStyle: "",
        image: "/assets/media/team/bibin.png",
    
      }, {
        fullName: "Alex Baby",
        dateOfBirth: "16/09/1997",
        placeOfBirth: "Koratty, Thrissur",
        height: "182cm",
        role: "All Rounder",
        battingStyle: "Right hand bat",
        bowlingStyle: "Right arm medium",
        nickName: "Power hitter",
        playingStyle: "Classist",
        fieldingStyle: "Lightning ⚡️",
        id: "b8415dfb-a770-4668-9390-980eb7267e47",
        crickId: "",
        image: "/assets/media/team/alex.png"
      }, {
        fullName: "Sarath",
        dateOfBirth: "15/02/1995",
        placeOfBirth: "Palakkad, Parli",
        height: "172 cm",
        role: "All Rounder",
        battingStyle: "Right hand bat",
        bowlingStyle: "Right arm medium pace",
        nickName: "Saru",
        playingStyle: "Steady batter",
        id: "88131977-0cd7-4d83-85c2-cae0ce401bca",
        crickId: "",
        fieldingStyle: "",
        image: "/assets/media/team/sarath.png"
      }, {
        fullName: "Shabeer",
        dateOfBirth: "02/06/1997",
        placeOfBirth: "Hassan, Karnataka",
        height: "5 ft 3 in",
        role: "Batting all Rounder",
        battingStyle: "Right hand bat",
        bowlingStyle: "Right arm medium pace",
        nickName: "Sharbat",
        playingStyle: "Classist",
        id: "7ad2b811-dbcb-4c35-8eaf-e494f90f54b6",
        crickId: "",
        fieldingStyle: "",
        image: "/assets/media/team/shabeer.png"
      }, {
        fullName: "Anoop Kodakkal Puthiyaveedu",
        dateOfBirth: "24/04/1987",
        placeOfBirth: "Payyannur,Kerala",
        height: "6ft 1in ( 185cm)",
        role: "All Rounder",
        battingStyle: "Right hand bat",
        bowlingStyle: "Right arm medium",
        nickName: "Koda",
        playingStyle: "Classist",
        id: "99394632-2e7f-4247-b16a-bcdb673ea91b",
        crickId: "",
        fieldingStyle: "",
        image: "/assets/media/team/anoop.png"
      }, {
        fullName: "Jithendra Reddy",
        dateOfBirth: "04/03",
        placeOfBirth: "",
        height: "5 ft 10",
        role: "Batsmen",
        battingStyle: "Right hand bat",
        bowlingStyle: "N/A",
        nickName: "Jakki",
        playingStyle: "Classist",
        id: "be5a0996-cace-4b5c-9037-be38c69df31d",
        crickId: "",
        fieldingStyle: "",
        image: "/assets/media/team/jithendra.png"
      }, {
        fullName: "Saurabh Thube",
        dateOfBirth: "18/10/1996",
        placeOfBirth: "Ahmednagar, Maharashtra",
        height: "178cm",
        role: "All Rounder",
        battingStyle: "Right hand bat",
        bowlingStyle: "Right arm leg spin",
        nickName: "-",
        playingStyle: "Destroyer, Aspirant",
        id: "5745d063-c735-4d5c-be92-4ef5efa75345",
        crickId: "",
        fieldingStyle: "",
        image: "/assets/media/team/saurabh.png"
      }, {
        fullName: "Sajith P M",
        dateOfBirth: "13/11/1995",
        placeOfBirth: "Thrissur, Kerala",
        height: "5 ft 8 in ( 173 cm)",
        role: "All Rounder",
        battingStyle: "Right hand bat",
        bowlingStyle: "Right arm medium",
        nickName: "Saj, Mr. Reliable",
        playingStyle: "Hard Hitter, Wildcard",
        fieldingStyle: "Gun fielder",
        id: "37d1f8e2-5760-4b72-a80b-48b27b654b76",
        crickId: "",
        image: "/assets/media/team/sajith.png"
      }, {
        fullName: "Arunkumar Manoharan",
        dateOfBirth: "05/07/1994",
        placeOfBirth: "Karur, Tamilnadu",
        height: "(172cm)",
        role: "All Rounder",
        battingStyle: "Left Hand Bat",
        bowlingStyle: "Left Arm Medium",
        nickName: "Padayappa",
        playingStyle: "Hard Hitter",
        id: "81fb3cfd-1efb-4810-994e-6b1769558d6f",
        crickId: "",
        fieldingStyle: "",
        image: "/assets/media/team/arun.png"
      }, {
        fullName: "Naveen Kumar",
        dateOfBirth: "31/10/1981",
        placeOfBirth: "Coimbatore, Tamilnadu",
        height: "170cm",
        role: "Wicket Keeper Batsman",
        battingStyle: "Right Hand bat",
        bowlingStyle: "Right arm Medium",
        nickName: "Naveen",
        playingStyle: "Accumulator Aspirant",
        id: "a226d5f2-179f-4330-806c-324c48faea76",
        crickId: "",
        fieldingStyle: "",
        image: "/assets/media/team/naveen.png"
      }, {
        fullName: "Gijo George K",
        dateOfBirth: "22/03/1992",
        placeOfBirth: "Ottappalam, Palakkad",
        height: "167cm",
        role: "Bowler",
        battingStyle: "Right hand bat",
        bowlingStyle: "Right arm off spin",
        nickName: "Lolan",
        playingStyle: "Classist",
        id: "3c05fed0-acee-47d2-96ff-85b6aa2c067a",
        crickId: "",
        fieldingStyle: "",
        image: "/assets/media/team/gijo.png"
      }, {
        fullName: "Premraj K K",
        dateOfBirth: "08/07/1992",
        placeOfBirth: "Payyannur, Kerala",
        height: "166cm",
        role: "Batsman/WK",
        battingStyle: "Right hand bat",
        bowlingStyle: "",
        nickName: "MSD Fanbwoy",
        playingStyle: "Classist",
        id: "526d812e-2947-480a-853e-29bfeab9aa2f",
        crickId: "",
        fieldingStyle: "",
        image: "/assets/media/team/premraj.png"
      }, {
        fullName: "Ragesh Puthiyedathu",
        dateOfBirth: "11/04/1986",
        placeOfBirth: "Payyannur, Kerala",
        height: "174cm",
        role: "Allrounder",
        battingStyle: "Right hand bat",
        bowlingStyle: "Right arm medium",
        nickName: "-",
        playingStyle: "Classisist, Aspirant",
        id: "5a05f3da-9d8e-489d-b4c2-74ac707cbf06",
        crickId: "",
        fieldingStyle: "",
        image: "/assets/media/team/ragesh.png"
      }, {
        fullName: "Jatin Somarajan",
        dateOfBirth: "19/05/1986",
        placeOfBirth: "Adoor, Kerala",
        height: "5 ft 5 in ( 165 cms)",
        role: "All Rounder",
        battingStyle: "Right hand bat",
        bowlingStyle: "Right arm medium",
        nickName: "Motta",
        playingStyle: "Classicist",
        id: "1b14d80d-f1f9-4078-bedb-c2d489e008de",
        crickId: "",
        fieldingStyle: "",
        image: "/assets/media/team/jatin.png"
      }, {
        fullName: "Dhiraj Choudhary",
        dateOfBirth: "05/09/1996",
        placeOfBirth: "Guwahati, Assam",
        height: "6 ft ( 183cm)",
        role: "All Rounder",
        battingStyle: "Right handed batsman",
        bowlingStyle: "Right arm off break",
        nickName: "DKC",
        playingStyle: "Builder and Destroyer",
        id: "de04e595-851b-4dd6-9045-a98f1c4e2c09",
        crickId: "",
        fieldingStyle: "",
        image: "/assets/media/team/dhiraj.png"
      }, {
        fullName: "Tobin Palayat",
        dateOfBirth: "21/02/1984",
        placeOfBirth: "Calicut, Kerala.",
        height: "172cm",
        role: "All Rounder",
        battingStyle: "Left hand bat",
        bowlingStyle: "Right arm Medium",
        nickName: "Michel Beven, Anchor",
        playingStyle: "Economist/Score Rotator",
        id: "15415427-5737-4aef-96c7-2cb40e75656e",
        crickId: "",
        fieldingStyle: "",
        image: "/assets/media/team/tobin.png"
      }, {
        fullName: "Mungara Rishi Kumar Reddy",
        dateOfBirth: "28/09/2003",
        placeOfBirth: "Gudur, Andhrapradesh",
        height: "181cm",
        role: "Bowler",
        battingStyle: "Right hand bat",
        bowlingStyle: "Right arm fast",
        nickName: "RK",
        id: "9074ca8b-b262-4385-8b59-ced8146284af",
        crickId: "",
        fieldingStyle: "",
        image: "/assets/media/team/rishi.png"
      }, {
        fullName: "Shivam Tamrakar",
        dateOfBirth: "06/10/1994",
        placeOfBirth: "Unchehra, Satna, MP",
        height: "5 ft 10 in ( 180 cm)",
        role: "All Rounder",
        battingStyle: "Right hand bat",
        bowlingStyle: "Right fast medium Wicket keeper as well",
        nickName: "NA",
        playingStyle: "Classist, Asprirant",
        id: "34148846-578a-4fbe-ad9e-687de0ab5c83",
        crickId: "",
        fieldingStyle: "",
        image: "/assets/media/team/shivam.png"
      }, {
        fullName: "Sathiyan",
        dateOfBirth: "28/10/1993",
        placeOfBirth: "Salem",
        height: "5 ft 6",
        role: "Batsman",
        battingStyle: "Right hand bat",
        bowlingStyle: "Right arm off break",
        nickName: "Maxy",
        playingStyle: "Classist",
        id: "9103e769-92e2-4b0f-93bc-673b240652b9",
        crickId: "",
        fieldingStyle: "",
        image: "/assets/media/team/sathiyan.png"
      }, {
        fullName: "Vijith Achar",
        dateOfBirth: "14-12-1993",
        placeOfBirth: "Kundapura, Udupi",
        height: "5 ft 8 inch",
        role: "All Rounder",
        battingStyle: "Right hand bat",
        bowlingStyle: "left arm pace bowler",
        nickName: "Vijith",
        playingStyle: "unpredictable",
        id: "35817b6d-702a-4160-a84a-a20dbac240bd",
        crickId: "",
        fieldingStyle: "",
        image: "/assets/media/team/vijith.png"
      }, {
        fullName: "Vineeth.K",
        dateOfBirth: "15/05/1986",
        placeOfBirth: "Bangalore",
        height: "5.10",
        role: "All Rounder",
        battingStyle: "Right hand bat",
        bowlingStyle: "Right arm medium pace",
        nickName: "Vinny",
        playingStyle: "Explosive",
        id: "a26bea27-ef2c-47eb-a22c-15c4582cc77d",
        crickId: "",
        fieldingStyle: "",
        image: "/assets/media/team/vineeth.png"
      }, {
        fullName: "Roopesh V",
        dateOfBirth: "22-09-1982",
        placeOfBirth: "Calicut kerala",
        height: "160cm",
        role: "Bowler",
        battingStyle: "right hand",
        bowlingStyle: "right hand medium",
        nickName: "kesu",
        playingStyle: "Nightwatchman",
        id: "fc6a0a5a-95da-45b2-937d-c038e249ad06",
        crickId: "",
        fieldingStyle: "",
        image: "/assets/media/team/roopesh.png"
      }, {
        fullName: "Ravishankar Prabhu",
        dateOfBirth: "4-11-1992",
        placeOfBirth: "Manipal, Udupi",
        height: "5 ft 9 inch",
        role: "Bowling all Rounder",
        battingStyle: "Right hand bat",
        bowlingStyle: "Right arm fast bowler",
        nickName: "Ravi",
        playingStyle: "Classsit",
        id: "f0a73fb3-123b-4e8b-bad3-1469f50c438a",
        crickId: "",
        fieldingStyle: "",
        image: "/assets/media/team/ravishankar.png"
      }, {
        fullName: "Ranjith.R",
        dateOfBirth: "22/01/1993",
        placeOfBirth: "Bangalore",
        height: "6 ft 1 in ( 185 cm)",
        role: "All Rounder",
        battingStyle: "Right hand bat",
        bowlingStyle: "Right arm Fast",
        nickName: "RJ",
        playingStyle: "Aspirant",
        id: "c479d8ae-982e-4a51-8049-202855c9f64e",
        crickId: "",
        fieldingStyle: "",
        image: "/assets/media/team/ranjith.png"
      }, {
        fullName: "Rajesh Mayya",
        dateOfBirth: "10/05/1998",
        placeOfBirth: "Mangalore",
        height: "180cm",
        role: "Batting",
        battingStyle: "Right hand bat",
        bowlingStyle: "Right arm medium break",
        nickName: "",
        playingStyle: "Classicist",
        id: "bb862a0c-a732-4470-98b7-38afa67e0f92",
        crickId: "",
        fieldingStyle: "",
        image: "/assets/media/team/rajesh.png"
      }
    ]
  },
  reducers: {
    get: (state) =>{
      
    }
  },
})

// Action creators are generated for each case reducer function
export const { get} = playerList.actions

export default playerList.reducer