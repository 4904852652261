import { useSelector } from 'react-redux';
import AppFooter from '../components/AppFooter';
import AppHeader from '../components/AppHeader';
import AppModal from '../components/AppModal';
import TeamMember from '../components/TeamMember';

const renderTeamMembers = (data:[TeamMember]) => {
  if(data && data?.length>0){
    const newObject:[TeamMember] = JSON.parse(JSON.stringify(data));
    let sortedData = newObject.sort((a,b) => a.fullName.localeCompare(b.fullName));
    // console.log("SortedData : "+JSON.stringify(sortedData))
    return sortedData.map(teamMember => {
      return <TeamMember key={teamMember.id} teamMember={teamMember} />;
  });
  }
};

function Team() {
  const playerList = useSelector((state:any) => state.playerList.list)
  return (
    <div className="">
      {/* <!-- Back To Top Start --> */}
      <a href="#main-wrapper" id="backto-top" className="back-to-top"><i
        className="fas fa-angle-up"></i></a>
      {/* <!-- Main Wrapper Start --> */}
      <div id="main-wrapper" className="main-wrapper overflow-hidden">
        <AppHeader activeMenu="team" />
        {/* <!-- Main Content Start --> */}
        <div className="page-content">
          {/* <!-- Team Start --> */}
          <section className="team p-40">
            <div className="container">
              <div className="row mb-32">
                {renderTeamMembers(playerList)}
              </div>
            </div>
          </section>
          {/* <!-- Team End --> */}
        </div>
        <AppFooter />
        <AppModal />
      </div>
    </div>
  );
}

export default Team;
