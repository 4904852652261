import '../App.css';

interface Props {
    itemData: TournamentResultItem
}


interface TournamentResultItem {
    "tournament_id": number;
    "tournament_name": string;
    "result": string;
}

function TournamentResultItem(props: Props) {
    return (

        <div className="result-block">
            <div className="item">
                    <h5 className="text-center dark-gray">{props?.itemData.tournament_name}</h5>
                    <h4 className="text-center light-black">{props?.itemData.result}</h4>
            </div>
        </div>
    );
}

export default TournamentResultItem;