import { useSelector } from 'react-redux';
import AppFooter from '../components/AppFooter';
import AppHeader from '../components/AppHeader';
import AppModal from '../components/AppModal';
import CompleteGallery from '../components/CompleteGallery';
import TeamMember from '../components/TeamMember';

import { useParams } from 'react-router-dom';
import PlayerDetailItem from '../components/PlayerDetailItem';
// 

const renderPlayerDetail = (data: [TeamMember], providedId: string) => {
  if (data && data?.length > 0) {
    const newObject: [TeamMember] = JSON.parse(JSON.stringify(data));
    let playerData: any = newObject.filter((obj) => obj.id === providedId);
    console.log("playerData : ", playerData);
    if (playerData && playerData.length > 0) {
      return <PlayerDetailItem key={playerData[0].id} teamMember={playerData[0]} />;
    } else {
      return <><h2 className="light-black mb-1">No Player Found</h2></>
    }
  }
};

function PlayerDetail() {
  const playerList = useSelector((state: any) => state.playerList.list)
  const params = useParams()
  const playerId: string = params.id as string;
  const playerName: string = params.name!;
  console.log("Player Id : ", playerId);
  console.log("Player Name : ", playerName);
  return (
    <div className="">
      <div id="main-wrapper" className="main-wrapper overflow-hidden">
        <AppHeader />
        {/* <!-- Main Content Start --> */}
        <div className="page-content">
          {renderPlayerDetail(playerList, playerId)}
        </div>
        <div className="page-title-banner">
          <div className="container">
            <div className="content">
              <h1 className="mb-16 light-black">Player Detail</h1>
              <p className="light-black">Beyond the Boundary: Unveiling Legends,<br />One Detail at a Time on Our Cricket Player Detail Page</p>
            </div>
          </div>
        </div>
        {/* <!-- Main Content End --> */}
        <AppFooter />
        <AppModal />
      </div>
    </div>
  );
}

export default PlayerDetail;
