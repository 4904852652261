import '../App.css';

function AppModal() {
    return (
        <div>
            {/* <!-- modal-popup area start  --> */}
            <div className="modal fade" id="videoModal" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true"></span>
                            </button>
                            <div className="ratio ratio-16x9">
                                <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/EzDC8aAJln0" id="video"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- modal-popup area end  --> */}
        </div>
    );
}

export default AppModal;