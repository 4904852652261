import '../App.css';

function HomeAchievements() {
    return (
        <div>
            {/* <!-- Achievements Start --> */}
            <section className="achievement">
                    <div className="celebrattion-image">
                        <img src="/assets/media/achievement/celebration-image.png" alt="" />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 offset-xl-6">
                                <div className="p-40">
                                    <h3 className="light-black mb-24">Fighters, Beyond the Boundary<br/>Crafting a Legacy of Excellence</h3>
                                    <p className="dark-gray mb-16">In the realm of cricket, where boundaries are marked by ropes, Fighters Cricket Club is rewriting the script, transcending limits, and etching a legacy beyond the boundary. Beyond the pitch and the stumps, our club stands as a testament to passion, resilience, and a commitment to excellence that extends far beyond the cricket field.</p>
                                    <p className="dark-gray mb-16">At Fighters Cricket Club, we are more than just teammates; we are a brotherhood bonded by a shared love for the game. Beyond the roar of the crowd and the thrill of victory, it's the camaraderie and support that define our spirit. Each member is a pillar, contributing to the strength that propels us forward.</p>
                                    <p className="dark-gray mb-32">While victories on the scoreboard are celebrated, Fighters Cricket Club understands that success goes beyond the win-loss column. It's about the resilience to bounce back from defeats, the humility to acknowledge areas of improvement, and the continuous pursuit of growth and learning. Beyond the scores, we measure success in the journey we undertake as a team.</p>
                                    <a href="player-detail.html" className="cus-btn primary">Read More
                                        <i className="fal fa-chevron-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="achievement-content ">
                            <div className="title light-black">ACHIEVEMENTS</div>
                            <div className="achievements">
                                <div className="content text-center">
                                    <h2>50+</h2>
                                    <h6 className="lightest-gray">Team Members</h6>
                                </div>
                                <div className="content text-center">
                                    <h2>2</h2>
                                    <h6 className="lightest-gray">Tournament
                                        Victories</h6>
                                </div>
                                <div className="content text-center">
                                    <h2>1</h2>
                                    <h6 className="lightest-gray">Runners up</h6>
                                </div>
                                <div className="content text-center">
                                    <h2>62%</h2>
                                    <h6 className="lightest-gray">Win Rate</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Achievements End --> */}
        </div>
    );
}

export default HomeAchievements;