import { Link } from 'react-router-dom';
import '../App.css';

interface Props {
    teamMember: TeamMember
}

interface TeamMember {
    fullName: string;
    dateOfBirth: string;
    placeOfBirth: string;
    height: string;
    role: string;
    battingStyle: string;
    bowlingStyle?: string;
    nickName: string;
    playingStyle?: string;
    id: string;
    crickId: string;
    image: string;
    fieldingStyle?: string;
}

function TeamMember(props: Props) {
    const name = props.teamMember?.fullName.replace(/ /g, '-');
    return (
        <Link className="col-xl-4 col-md-6" to={"/pd/" + props.teamMember?.id + "/" + name}>
            <div className="team-member mb-24">
                <div className="content text-center pt-20p">
                    <img className="pi-300p" src={props?.teamMember?.image} alt="" />
                    <div className="info pl-20p text-center">
                        <h4 className="light-black mt-2">{props?.teamMember?.fullName}</h4>
                        {/* <h6 className="dark-gray mt-2">Dob:{props?.teamMember?.dateOfBirth}</h6> */}
                        <h6 className="dark-gray mt-2 text">Nick: <b>{props?.teamMember?.nickName}</b></h6>
                        <h6 className="dark-gray mt-2 mb-12">Role : <b>{props?.teamMember?.role}</b></h6>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default TeamMember;