import AppFooter from '../components/AppFooter';
import AppHeader from '../components/AppHeader';
import AppModal from '../components/AppModal';
import HomeMatchResults from '../components/HomeMatchResults';

function Matches() {
  return (
    <div className="">
      {/* <!-- Back To Top Start --> */}
      <a href="#main-wrapper" id="backto-top" className="back-to-top"><i
        className="fas fa-angle-up"></i></a>
      {/* <!-- Main Wrapper Start --> */}
      <div id="main-wrapper" className="main-wrapper overflow-hidden">
        <AppHeader  activeMenu='matches'  />
        {/* <!-- Main Content Start --> */}
        <div className="page-content">
          <HomeMatchResults showAll={true} />
        </div>
        <AppFooter />
        <AppModal />
      </div>
    </div>
  );
}

export default Matches;
