import '../App.css';

interface Props {
    teamMember: TeamMember
}

interface TeamMember {
    fullName: string;
    dateOfBirth: string;
    placeOfBirth: string;
    height: string;
    role: string;
    battingStyle: string;
    bowlingStyle?: string;
    nickName: string;
    playingStyle?: string;
    id: string;
    crickId: string;
    image: string;
    fieldingStyle?: string;
}

function PlayerDetailItem(props: Props) {
    return (
        <>
        {/* <!-- Player info Start --> */}
        <section className="player-info p-40">
        <div className="container position-relative">
            <div className="vector">
                <img src="/assets/media/vector/vector.png" alt="" />
            </div>
            <div className="row">
            <div className="col-xl-6">
                    <div className="player-detail">
                        <div className="image">
                            <img src={props?.teamMember?.image} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6">
                    <div className="player-content">
                        <div className="info">
                            <h2 className="light-black mb-1">{props?.teamMember?.fullName}</h2>
                            <h4 className="light-black mb-12">Bio:</h4>

                            <h6 className="dark-gray mb-12">Nick Name : <span className="light-black">{props?.teamMember?.nickName}</span></h6>
                            
                            <h6 className="dark-gray mb-12">Role : <span className="light-black">{props?.teamMember?.role}</span></h6>
                            <h6 className="dark-gray mb-12">Height : <span className="light-black">{props?.teamMember?.height}</span></h6>
                            <h6 className="dark-gray mb-12">Batting Style : <span className="light-black">{props?.teamMember?.battingStyle}</span></h6>
                            <h6 className="dark-gray mb-12">Bowling Style : <span className="light-black">{props?.teamMember?.bowlingStyle}</span></h6>
                            <h6 className="dark-gray mb-12">Playing Style : <span className="light-black">{props?.teamMember?.playingStyle}</span></h6>
                            {props?.teamMember?.fieldingStyle !== "" && 
                                <h6 className="dark-gray mb-12">Fielding Style : <span className="light-black">{props?.teamMember?.fieldingStyle}</span></h6>
                            }
                            {props?.teamMember?.placeOfBirth !== "" && 
                                <h6 className="dark-gray mb-12">Place Of Birth : <span className="light-black">{props?.teamMember?.placeOfBirth}</span></h6>
                            }
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    {/* <!-- Player info End --> */}

    {/* <!-- About player Start --> */}
    {/* <section className="about-player">
        <div className="container">
            <div className="col-lg-6 col-md-8 col-sm-12 ">
                <h3 className="mb-32">About Player</h3>
                <h5 className="mb-16">Biography</h5>
                <p className="mb-16">Lorem ipsum dolor sit amet consectetur. Lobortis sed consectetur diam aliquam. Adipiscing condimentum fermentum pharetra venenatis feugiat eget eget. Pellentesque enim iaculis auctor nulla ornare faucibus. Diam diam lorem ut lectus dis eget ultricies. Id mattis nibh eu lobortis nisl lacus mollis.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Lobortis sed consectetur diam aliquam. Adipiscing condimentum fermentum pharetra venenatis feugiat eget eget. Pellentesque enim iaculis auctor nulla ornare faucibus.</p>
            </div>
        </div>
    </section> */}
    {/* <!-- About player End --> */}
    </>
    );
}

export default PlayerDetailItem;