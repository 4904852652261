import '../App.css';

interface Props {
    itemData: HomeMatchResultItem
}

interface matchSummary {
    "team_id": number;
    "summary": string;
    "short_summary": string;
    "full_summary": string;
    "rrr": string;
    "target": string;
}

interface teamSummary {
    "score": string;
    "over": string;
    "ball": string;
    "rr": string;
}

interface teamDetail {
    "team_id": number;
    "inning": number;
    "inning_start_time": string;
    "inning_end_time": string;
    "is_declare": number;
    "is_forfeited": number;
    "is_followon": number;
    "total_run": number;
    "total_wicket": number;
    "total_extra": number;
    "overs_played": string;
    "balls_played": number;
    "revised_target": number;
    "revised_overs": number;
    "lead_by": number;
    "trail_by": number;
    "summary": teamSummary;
}

interface HomeMatchResultItem {
    "match_id": number;
    "match_type": string;
    "is_super_over": number;
    "match_event_type": string;
    "match_event": string;
    "match_type_id": number;
    "match_inning": number;
    "ball_type": string;
    "current_inning": number;
    "match_start_time": string;
    "match_end_time": string;
    "created_date": string;
    "created_by": number;
    "city_id": number;
    "city_name": string;
    "ground_id": number;
    "ground_name": string;
    "overs": number;
    "balls": null,
    "over_reduce": string;
    "is_dl": number;
    "is_vjd": number;
    "type": number;
    "status": string;
    "winning_team_id": string;
    "winning_team": string;
    "match_result": string;
    "win_by": string;
    "team_a_id": number;
    "team_a": string;
    "team_a_logo": string;
    "is_a_home_team": number;
    "team_b_id": number;
    "team_b": string;
    "team_b_logo": string;
    "is_b_home_team": number;
    "tournament_id": string;
    "tournament_name": string;
    "tournament_category_id": string;
    "tournament_round_id": string;
    "tournament_round_name": string;
    "association_id": null,
    "association_year_id": null,
    "association_name": string;
    "association_logo": string;
    "steaming_url": string;
    "is_ticker": number;
    "is_enable_tournament_streaming": number;
    "is_enable_match_streaming": number;
    "is_video_analyst": number;
    "is_backend_match": number;
    "latitude": number;
    "longitude": number;
    "is_fake_match": number;
    "is_live_match_enable_in_web": number;
    "is_live_match_enable_in_app": number;
    "match_category_name": string;
    "is_watch_live": number;
    "index": number;
    "match_summary": matchSummary,
    "team_a_summary": string;
    "team_a_innings": [teamDetail],
    "team_b_summary": string;
    "team_b_innings": [teamDetail],
    "toss_details": string;


}

function HomeMatchResultItem(props: Props) {
    return (

        <div className="result-block">
            <div className="item">
                {props?.itemData.status === 'past' &&
                    <>
                        {props?.itemData.tournament_name !== '' && <h6 className="text-center dark-gray">{props?.itemData.tournament_name}  ({props?.itemData.tournament_round_name})</h6>}
                        <div className="teams">
                            <div className="team">
                                <div className="team-logo-name">
                                    <h6 className="dark-gray">Batting First</h6>
                                    {/* {props?.itemData.team_a==='Fighters Cricket Club' && <img src="/assets/media/logo.png" alt="" />} */}
                                    <h4 className={props?.itemData.team_a===props?.itemData.winning_team?"light-black":"dark-gray"}>{props?.itemData.team_a}</h4>
                                    <h5 className={props?.itemData.team_a===props?.itemData.winning_team?"light-black":"dark-gray"}>{props?.itemData.team_a_innings[0].summary.score} </h5>
                                    <h6 className={props?.itemData.team_a===props?.itemData.winning_team?"light-black":"dark-gray"}>{props?.itemData.team_a_innings[0].summary.over}</h6>
                                </div>
                            </div>
                            <div className="team">
                                <div className="team-logo-name">
                                    <h4 className="light-black">Vs</h4>
                                </div>
                            </div>
                            <div className="team">
                                <div className="team-logo-name">
                                    <h6 className="dark-gray">Batting Second</h6>
                                    <h4 className={props?.itemData.team_b===props?.itemData.winning_team?"light-black":"dark-gray"}>{props?.itemData.team_b}</h4>
                                    <h5 className={props?.itemData.team_b===props?.itemData.winning_team?"light-black":"dark-gray"}>{props?.itemData.team_b_innings[0].summary.score} </h5>
                                    <h6 className={props?.itemData.team_b===props?.itemData.winning_team?"light-black":"dark-gray"}>{props?.itemData.team_b_innings[0].summary.over}</h6>
                                    {/* {props?.itemData.team_b==='Fighters Cricket Club' && <img src="/assets/media/logo.png" alt="" />} */}
                                </div>
                            </div>
                        </div>
                        <h5 className="text-center light-black">{props?.itemData.match_summary.summary}</h5>
                    </>
                }
                {props?.itemData.status === 'upcoming' &&
                    <>
                    {props?.itemData.tournament_name !== '' && <h6 className="text-center dark-gray">{props?.itemData.tournament_name}  ({props?.itemData.tournament_round_name})</h6>}
                        <div className="teams">
                            <div className="team">
                                <div className="team-logo-name">
                                    <h4 className="light-black">{props?.itemData.team_a}</h4>
                                </div>
                            </div>
                            <div className="team">
                                <div className="team-logo-name">
                                    <h4 className="light-black">Vs</h4>
                                </div>
                            </div>
                            <div className="team">
                                <div className="team-logo-name">
                                    <h4 className="light-black">{props?.itemData.team_b}</h4>
                                </div>
                            </div>
                        </div>
                        <h6 className="text-center dark-gray">{props?.itemData.tournament_name}</h6>
                    </>
                }
            </div>
        </div>
    );
}

export default HomeMatchResultItem;